<template>
  <v-container    
    id="crud"
    fluid
    tag="section"
    class="px-6 mx-0"
    style="font-family: 'Inter', sans-serif"
  >
  <!--
    <div v-if="$store.state.carrito.data.length == 0">
      <div
        style="height: 320px"
        class="carritoMsg__div px-2 d-flex flex-column justify-center align-items-center"
      >
        <div style="position: relative; display: inline-block">
          <v-icon
            color="text-center"
            style="font-size: 5rem; top: 0; left: 45%; pointer-events: none"
            >mdi-cart-outline</v-icon
          >

          <v-btn
            class="text-center primary"
            dark
            elevation="0"
            fab
            icon
            x-small
            style="position: absolute; top: 0; left: 47%; pointer-events: none"
          >
            <span style="font-size: 14px; padding: 5px">0</span>
          </v-btn>
        </div>
        <br />
        <p
          style="line-height: 1; font-weight: 500"
          class="display-1 text-center"
        >
          Tu carrito está vacio
        </p>
        <small style="line-height: 1; color: #999" class="text-center">
          Actualmente no hay productos en su carrito.<br />Puede seguir
          comprando y agregar los productos deseados.
        </small>
      </div>
    </div>
    <div v-else>
    -->
    <div>
      <v-row>
        <v-col cols="12" md="8" class="pl-4 pr-4">
          <v-card
            outlined
            class="elevation-3 d-flex justify-center align-center"
            v-if="isLoading"
            style="height: 300px"
          >
            <v-progress-circular indeterminate color="primary"
              ><br /><small
                class="text-center"
                style="margin-top: 105px !important"
                >obteniendo datos...</small
              ></v-progress-circular
            >
          </v-card>

          <v-card outlined class="elevation-3" v-else>
            <v-row  class="pa-6" >
              <v-col cols="2" v-if="facturaSeleccionada.PointOfIssueCode">
                <v-img
                  :src="'/img/'+ empresa[facturaSeleccionada.PointOfIssueCode].logo"
                  contain
                  max-width="100"
                  class="mb-4"
                ></v-img>
              </v-col>
              <v-col cols="2" v-else>
                <v-img
                  :src="'/img/mj_logo.png'"
                  contain
                  max-width="100"
                  class="mb-4"
                ></v-img>
              </v-col>
              <v-col  cols="7">
                <!-- <div>{{ (facturaSeleccionada.PointOfIssueCode)?empresa[facturaSeleccionada.PointOfIssueCode].empresa : (facturaSeleccionada.convenio_marco)? facturaSeleccionada.convenio_marco:'' }}</div> -->
                <div>{{ (facturaSeleccionada.mostrarCM==0)?empresa[facturaSeleccionada.PointOfIssueCode].empresa : (facturaSeleccionada.convenio_marco)? facturaSeleccionada.convenio_marco:'' }}</div>
                
                <div style="font-size: 0.8rem; color: #999">
                  Av. Cordoba 3371 (1187), CABA
                </div>
                <div style="font-size: 0.8rem; color: #999">
                  (1187) C.A.B.A.
                </div>

              </v-col>
              <v-col cols="3" class="d-flex flex-column align-end">
                <div
                  style="
                    width: 250px;
                    background: #f4f5f6;
                    border-radius: 5px;
                    text-align: center;
                    font-weight: 700;
                    padding-top: 5px;
                    padding-bottom: 5px;
                  "
                >
                  Nota de pedido N°{{ facturaSeleccionada.DocEntry }}
                </div>
                <div style="font-size: 0.8rem; color: #999">
                  Monto Total
                </div>
                <div style="font-size: 1.4rem; font-weight: 700; text-align: center">
                  {{ (facturaSeleccionada)?'$'+$formatMoney(facturaSeleccionada.DocTotal):'' }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
                style="font-weight: 700; text-align: center"
              >
                {{ facturaSeleccionada.CardCode }} -
                {{ facturaSeleccionada.CardName }}
              </v-col>
            </v-row>
            <v-row class="pa-0 ml-8">
              <div
                class="px-4"
                style="
                  background-color: #e5f3ff;
                  border-radius: 8px;
                  width: 95% !important;
                "
              >
                <div
                  class="text-caption font-weight-bold py-1"
                  style="font-size: 1rem !important"
                >
                  Dirección de facturación
                </div>

                <div class="text-caption grey--text py-1">
                  {{ (facturaSeleccionada.AddressExtension)?facturaSeleccionada.AddressExtension.ShipToStreet:'' }}
                </div>
                <div class="text-caption grey--text py-1">
                  {{ (facturaSeleccionada.AddressExtension)?facturaSeleccionada.AddressExtension.BillToCity:'' }}
                </div>
                <div class="text-caption grey--text py-1">
                  C.U.I.T {{ facturaSeleccionada.FederalTaxID }}
                </div>
              </div>
            </v-row>
            <v-row class="pa-0 ml-6 mr-6 mt-6">
              <!-- Tabla de productos -->
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                    <tr style="background-color: #fafafa">
                      <th class="table-th text-left">Descripción</th>
                      <th class="table-th text-left">Cantidad</th>
                      <th class="table-th text-left">Precio</th>
                      <th class="table-th text-left">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(i, index) in facturaSeleccionada.DocumentLines"
                      :key="index"
                    >
                      <td class="table-td">{{ i.ItemDescription }}</td>
                      <td class="table-td">{{ i.Quantity }}</td>
                      <td class="table-td text-right">
                        $ {{ $formatMoney(i.Price) }}
                      </td>
                      <td class="table-td text-right">
                        $ {{ $formatMoney(i.LineTotal) }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row class="pa-0 ml-6 mr-6 mt-6">
              <v-col cols="12" md="6" class="offset-6 text-right">
                 <!--
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" md="7" class="text-left pa-1">
                    <div class="grey--text" style="font-size: 0.8rem">
                      Subtotal
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    class="text-right font-weight-bold pa-1"
                    >$ {{ $formatMoney(facturaSeleccionada.subtotal) }}
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" md="7" class="text-left pa-1">
                    <div class="grey--text" style="font-size: 0.8rem">
                      Impuestos sobre las ventas
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    class="text-right font-weight-bold pa-1"
                  >
                    $ {{ $formatMoney(facturaSeleccionada.impuestos) }}
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0 borderBottom">
                  <v-col cols="12" md="7" class="text-left pa-1">
                    <div class="grey--text" style="font-size: 0.8rem">
                      Manipulación y Envío
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    class="text-right font-weight-bold pa-1"
                    >${{ $formatMoney(facturaSeleccionada.envio) }}
                  </v-col>
                </v-row>
-->
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" md="7" class="text-left pa-1">
                    <div class="font-weight-bold text-subtitle-1">
                      Total a pagar
                    </div>
                  </v-col>
                  <v-col cols="12" md="5" class="text-right pa-1">
                    <div class="font-weight-bold text-subtitle-1">
                      ${{ $formatMoney(facturaSeleccionada.DocTotal) }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="facturaSeleccionada.type	== 'oInvoices'" class="pa-0 ml-8">
              <div
                class="px-4"

              >
                <div
                  class="text-caption font-weight-bold py-1"
                  style="font-size: 1rem !important"
                >
                Este documento no es una factura
                </div>
                </div>
            </v-row>

            <!--Inicio BNA EFECTIVO y cheque -->
            <v-row  v-if="facturaSeleccionada.U_FPago	== 1 || facturaSeleccionada.U_FPago	== 3">
              <v-col cols="12" md="12" class="">
                <table id="deposito" class="table-5" style="">
                  <tbody>

                    <tr v-for="n in 2" :key="n">
                      <td style="width:40px; border: 1px solid #000; text-align: center; vertical-align: bottom; max-width: 40px; overflow: hidden;">
                          <span v-if="n==1" style="display: block; transform: rotate(270deg); white-space: nowrap;margin-bottom: 30px;">
                            Comprobante Depositante
                          </span>
                          <span v-else style="display: block; transform: rotate(270deg); white-space: nowrap;margin-bottom: 30px;">
                            Comprobante Banco
                      </span>

                      </td>
                      <td style=" border: 1px solid #000;">
                          <table style="padding-left:5px">
                              <tr>

                                  <td style="width:430px;padding-top:1px;">
                                      <span class="bold" style="font-size:0.7rem">
                                          BANCO DE LA NACION ARGENTINA
                                      </span>

                                  </td>

                                  <td style="margin-top: 2px;">
                                      <span class="bold" style="font-size:0.7rem;text-align:end;border: 1px solid #000;padding: 5px;margin-bottom: 0px;">
                                          Boleta de Depósito N° {{ facturaSeleccionada.deposit_number }}
                                      </span>
                                  </td>
                              </tr>
                          </table>

                          <table class="bold" style="padding-left:7px;font-size:0.7rem;">
                              <tr>
                                  <td style="font-weight: bold;">
                                  {{ facturaSeleccionada.convenio_marco }}
                                  </td>

                              </tr>
                              <tr>
                                  <td>

                                      Convenio Recaudacion N° {{ facturaSeleccionada.convenio_recaudacion }}
                                  </td>
                                  <td style = "width: 20px;"></td>
                                  <td rowspan="6" style="width: 155px; text-align:center; border: 1px solid #000; position: relative; vertical-align: top;">
                                      <div style="position: absolute; top: 5px; left: 0; right: 0;">
                                          {{ facturaSeleccionada.deposit_date }} {{ facturaSeleccionada.deposit_time }}
                                      </div>
                                      <div style="position: absolute; top: 110px; left: 0; right: 0; font-weight: bold;">
                                          Sello Caja Banco
                                      </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      Depositante: {{ facturaSeleccionada.CardName }} C.U.I.T: {{ facturaSeleccionada.CardCode }}
                                  </td>
                                  <td></td>
                              </tr>
                              <tr>
                                  <td>
                                      <table class="table-5">
                                          <tr>
                                              <td class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                  Vencimiento: {{ facturaSeleccionada.vencimiento }}
                                              </td>
                                              <td v-if="facturaSeleccionada.U_FPago == 6" class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                      Importe:
                                                  ${{ $formatMoney(facturaSeleccionada.DocTotal)}}
                                              </td>
                                              <td v-else-if="facturaSeleccionada.U_FPago == 1" class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                      Efectivo:
                                                  ${{ $formatMoney(facturaSeleccionada.DocTotal)}}
                                              </td>
                                              <td v-else class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                  Cheque:

                                              ${{ $formatMoney(facturaSeleccionada.DocTotal) }}
                                          </td>
                                          </tr>
                                      </table>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      <!-- Código de barras -->
                                      <div style="text-align: center; margin-top: 10px;">
                                          <!-- Mostrar la imagen del código de barras -->
                                          <img :src="facturaSeleccionada.codigoBarrasImg" alt="Código de barras" style="width: 100%; max-width: 260px;" />
                                      </div>
                                      <p style="text-align: center; font-size: 15px; font-weight: normal; margin-top: -5px;">
                                          <!-- Mostrar el texto del código de barras -->
                                          {{ facturaSeleccionada.codigoBarras }}
                                      </p>
                                  </td>
                              </tr>
                          </table>

                              <p v-if="facturaSeleccionada.U_FPago	== 1" style="padding-left:15px; font-size: 0.6rem;height:20px;">Son Pesos: {{ facturaSeleccionada.PriceLetters }}</p>

                              <p v-else style="padding-left:15px; font-size: 0.6rem;height:20px;">Son Pesos: {{ facturaSeleccionada.PriceLetters }}  Cheque a la orden del Banco de la Nacion Argentina <br>Banco {{ facturaSeleccionada.U_CheckBankName }}. Cheque {{(facturaSeleccionada.U_CheckNumber)?facturaSeleccionada.U_CheckNumber:'' }}. Vto: {{(facturaSeleccionada.U_CheckDueDate)?facturaSeleccionada.U_CheckDueDate:''}}</p>

                      </td>
                  </tr>

                  </tbody>
                </table>
              </v-col>
            </v-row>
            <!--Fin BNA-->
            <v-row  v-else-if="facturaSeleccionada.U_FPago == 6">
            <!--Inicio BTB-->
              <v-col cols="12" md="12" class="">
                <table id="deposito" class="table-5" style="">
                  <tbody>
                    <tr>
                        <td style="width:40px; border: 1px solid #000;"></td>
                        <td style=" border: 1px solid #000;">
                            <table style="padding-left:5px">
                                <tr>
                                    <td style="width:30%;min-width:30%;">
                                        <img src="/img/interbanking.png" width="140" height="30" border="0" style="margin-top:0px;">
                                    </td>
                                    <td style="width:250px;padding-top:10px;">
                                        <span v-if="facturaSeleccionada.db.indexOf('mj') > -1" class="bold" style="font-size:0.7rem">
                                            RA AUTOM LEYES 23283 Y 23412 - 1601138/20
                                        </span>
                                    </td>
                                    <td style="margin-top: 2px;">
                                        <span class="bold" style="font-size:0.7rem;text-align:end;border: 1px solid #000;padding: 2px;margin-bottom: 0px;">
                                            Boleta de Depósito N° {{ facturaSeleccionada.deposit_number }}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table class="bold" style="padding-left:7px;font-size:0.7rem;">
                                <tr>
                                    <td style="width: 390px;">
                                        Convenio Recaudacion N° {{ facturaSeleccionada.convenio_recaudacion }}
                                    </td>
                                    <td style="width: 200px;text-align:center">
                                        {{ facturaSeleccionada.deposit_date }} {{ facturaSeleccionada.deposit_time }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Depositante: {{ facturaSeleccionada.depositante }} C.U.I.T: {{ facturaSeleccionada.depositante_cuit }}
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table class="table-5">
                                            <tr>
                                                <td class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                    Vencimiento: {{ facturaSeleccionada.vencimiento }}
                                                </td>
                                                <td class="bold" style="width:200px;text-align:center;font-size:0.8rem;border: 1px solid #000;">
                                                    Importe: ${{ $formatMoney(facturaSeleccionada.DocTotal) }}
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <p class="bold" style="font-size: 1.5rem; width:400px;text-align:center;height:30px">{{ facturaSeleccionada.estado }}</p>
                            <p style="padding-left:15px;font-size: 0.6rem;height:20px">Son Pesos: {{ facturaSeleccionada.PriceLetters }}</p>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <!--Fin BTB-->
            </v-row>
            <v-row v-else>
              <v-col cols="12" md="12" sm="12" >
                <table class="table-5">
                  <tbody>
                    <tr>
                      <td class="border-box">
                        Exceptuado del cumplimiento R.G. 1415/2003, según inciso a) del apartado 'A' del anexo I
                      </td>
                    </tr>
                  </tbody>
                </table>

              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" class="pa-4">
          <p class="text-h4 text-center mt-4 pb-6" style="">
            Facturas generadas
          </p>

          <v-row
            v-for="(f, index) in facturas"
            :key="index"
            :style="index > 0 ? 'border-top: #ddd solid 1px;' : ''"
          >
            <v-col cols="12" md="10">
              <span class="text-inter" style="color: #0078d4">{{ (f.db)?f.db:'' }} </span
              ><span style="color: #000">Nro Pedido: {{ f.DocEntry }}</span>
            </v-col>
            <v-col cols="12" md="2">
              <v-icon @click="loadData(index)" style="color: #333"
                >mdi-eye</v-icon
              >
              <v-icon @click="download(index)" style="color: #333"
                >mdi-download</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
export default {
  components: {},

  data: (vm) => ({
    snackbar: false,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    isLoading: false,
    empresa:{'00001':{
        empresa: "ACARA",
        direccion: "Av. Cordoba 3371 (1187), CABA",
        telefonoCorreo: "115236 8500 | socios@acara.org.ar",
        logo: "acara_logo_2.png",
    },
    '00002':{
        empresa: "MINISTERIO DE JUSTICIA",
        direccion: "Av. Cordoba 3371 (1187), CABA",
        telefonoCorreo: "115300 4000 | infoanm@jus.gob.ar",
        logo: "mj_logo.png",
    },
   } ,
    facturas: [],
    facturaSeleccionada: {},
  }),

  mounted() {
    this.facturas = this.$route.params.facturas;
    this.facturaSeleccionada = this.facturas[0]; // Selecciona el primer elemento al cargar
    console.log(this.facturaSeleccionada.mostrarCM);

  },

  methods: {
    loadData(index) {     
      this.isLoading = true;
      setTimeout(() => {
        this.facturaSeleccionada = this.facturas[index]; // Cambia la factura seleccionada
        this.isLoading = false;        
      }, 1000);
    },
    download(index) {      
      Swal.alertGetInfo("Buscando información");
      let id = this.facturas[index].DocEntry;
      let db = this.facturas[index].db;
      let mp = this.facturas[index].U_FPago;
      let url = '';
      if(this.facturas[index].PointOfIssueCode){
        url = (mp != 5) ?"/api/servicelayer/pdf/drafts/"+id+"/"+db:"/api/servicelayer/pdf/invoices/"+id+"/"+db;
      }
      else{
        url = "/api/servicelayer/pdf/orders/"+id+"/"+db;
      }
      
      this.$axiosApi
        .sendRequestPdfLoad(url, "GET")
        .then((response) => {
          Swal.close();
          //this.isLoading = false;
          if (response.data.size <= 0) {
            Swal.fire("Atención!", "No se pudieron obtener los datos", "info");
            return;
          }
          let blob = new Blob([response.data], {
              type: response.data.type,
            }),
            url = window.URL.createObjectURL(blob);

          window.open(url);
        })
        .catch(console.error);
    },
  },
  watch: {},
  computed: {
    convenioRecaudacion() {
      const dbCode = this.facturaSeleccionada.db;
      const convenioData = this.$store.state.conveniosRecaudacion[dbCode];
      return convenioData ? convenioData.U_NroConvenio : "";
    },
  },
};
</script>

<style scoped>
.border-box {
  border: 1px solid #000000;


  border: 1px solid #000; /* Bordes en todos los lados */
            padding: 5px;
            box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
}
.table-th {
  color: #757575;
  font-weight: 500;
}

.table-td {
  color: #000000;
  border-bottom: #ddd solid 1px;
  font-size: 12px !important;
}
.borderBottom {
  border-bottom: #ddd solid 1px;
}

.text-h4 {
  font-weight: 700 !important;
  font-family: "Encode Sans" !important;
}
.text-inter {
  font-family: "Inter", sans-serif;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}

.table-5 {
  width: 98%;
  border-collapse: collapse;
  margin: 5px 5px 5px 5px;
  font-family: "Noto Sans", sans-serif;
}

</style>
