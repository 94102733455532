<template>
  <v-container id="crud" fluid tag="section" class="px-6">
    <v-toolbar flat color="white" class="pr-4">
      <v-row>
        <v-col sm="4" class="pt-5 pl-2 small">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="'Búsqueda de archivos'"
            single-line
            hide-details
            dense
            rounded
            solo
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-toolbar flat color="white" class="pt-6">
      <v-toolbar-title class="font-weight-medium">
        {{ title }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        dark
        class="mx-2"
        fab
        small
        @click="nuevoArchivo()"
        v-if="$can([titlePerms + '_create'])"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="filteredData"
        sort-by="id"
        :search="search"
        sort-desc
        class="elevation-1 custom-striped-table"
        style="width:100%;"
      >

        <template v-slot:header.nombre="{ header }">
          <th style="font-size: 0.9rem; text-align: left; width: 60%;">
            {{ header.text }}
          </th>
        </template>

        <template v-slot:header.created_at="{ header }">
          <th style="font-size: 0.9rem; text-align: center; width: 20%;">
            {{ header.text }}
          </th>
        </template>

        <template v-slot:header.actions="{ header }">
          <th style="font-size: 0.9rem; text-align: center; width: 20%;">
            {{ header.text }}
          </th>
        </template>

        <template v-slot:[`item.nombre`]="{ item }">
          <td style="width: 60%; vertical-align: middle;">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{ item.titulo }}
            </div>
          </td>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          <td style="width: 20%; text-align: center; vertical-align: middle;">
            {{ item.created_at }}
          </td>
        </template>

        <template v-slot:[`item.tipo_entidades`]="{ item }">
          <td style="width: 20%; vertical-align: middle;">
            <v-chip
              v-for="(tag, index) in JSON.parse(item.tipo_entidades)"
              :key="index"
              small
              class="mr-1"
            >
              {{ tag.nombre }}
            </v-chip>
          </td>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <td style="width: 20%; text-align: center; vertical-align: middle;">
            <v-menu
              bottom
              origin="center center"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent elevation-0"
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                  min-width="30px"
                >
                  <v-icon
                    large
                    color="blue-grey lighten-3"
                    style="font-size: 30px"
                  >
                    mdi-dialpad
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  @click="
                    vista = false;
                    editItem(item.id);
                  "
                  v-if="$can([titlePerms + '_update'])"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon small class="mr-2" :color="$cv('btnVista')" title="Editar">
                      mdi-pencil
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- Ver -->
                <v-list-item
                  @click="
                    vista = true;
                    editItem(item.id);
                  "
                  v-if="$can([titlePerms + '_read'])"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon small class="mr-2" :color="$cv('btnVista')" title="Ver">
                      mdi-eye-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Ver</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="download(item)"
                  v-if="$can([titlePerms + '_download'])"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon medium class="mr-2" color="primary" title="Ver archivo">
                      mdi-eye-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Ver Archivo</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="download(item, true)"
                  v-if="$can([titlePerms + '_download'])"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon medium class="mr-2" color="primary" title="Descargar">
                      mdi-download
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Descargar Archivo</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="$enabledItem(item)"
                  v-if="$can([titlePerms + '_changestatus'])"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon
                      medium
                      class="mr-2"
                      :color="item.activo == 1 ? $cv('btnInactivo') : $cv('btnActivo')"
                      title="Activar/Inactivar"
                    >
                      {{
                        item.activo == 1
                          ? "mdi-archive-arrow-down"
                          : "mdi-archive-arrow-up"
                      }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        item.activo == 1
                          ? $cv("titleDesarchivar")
                          : $cv("titleArchivar")
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="$can([titlePerms + '_delete'])"
                  @click="deleteItem(item.id, item.titulo)"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon
                      small
                      class="mr-2"
                      :color="$cv('btnEliminar')"
                      title="Eliminar"
                    >
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Eliminar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>

    <v-dialog
      v-model="dialog"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ formTitle }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn
              dark
              text
              v-if="!vista"
              :disabled="!valid"
              @click="saveWithFile()"
            >
              Guardar
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Título"
                    v-model="editedItem.titulo"
                    :disabled="vista"
                    :rules="[
                      $rulesRequerido,
                      $rulesAlfaNum,
                      $rulesMax500,
                    ]"
                  >
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="py-0"
                  v-if="!editedItem.archivo.name"
                >
                  <small class="text--info">
                    Selecciona el archivo a subir. Formatos permitidos: pdf, xlsx, docx, etc.
                  </small>
                  <v-file-input
                    chips
                    v-model="editedItem.archivo"
                    :rules="fileRules"
                    :disabled="vista"
                    label="Archivo"
                  ></v-file-input>
                </v-col>

                <v-col
                  v-else
                  cols="12"
                  md="12"
                  sm="12"
                  class="py-3"
                >
                  <v-chip
                    label
                    color="default"
                    close
                    :disabled="vista"
                    @click:close="removeFile(editedItem.id)"
                  >
                    {{ editedItem.archivo.name }}
                  </v-chip>
                </v-col>

                <v-col cols="12" md="12" sm="12" class="py-0">
                  <small>
                    Selecciona los tipos de entidad que van a poder
                    ver el archivo.
                  </small>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0">
                  <v-autocomplete
                    prepend-inner-icon="mdi-card-account-details"
                    :items="tipo_entidades"
                    item-value="id"
                    item-text="nombre"
                    label="Tipo Entidad"
                    v-model="editedItem.tipo_entidades"
                    :rules="[$rulesRequerido]"
                    :disabled="vista"
                    multiple
                    return-object
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="12" sm="12" class="py-0">
                  <v-switch
                    v-model="editedItem.activo"
                    label="Visible"
                    color="primary"
                    :false-value="0"
                    :true-value="1"
                    :disabled="vista"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";

function title() {
  return "Archivos";
}

export default {
  data() {
    return {
      valid: true,
      dialog: false,
      vista: false,
      editedIndex: -1,
      editedItem: {
        titulo: "",
        archivo: {},
        activo: 1,
        tipo_entidades: []
      },
      defaultItem: {
        titulo: "",
        archivo: {},
        activo: 1,
        tipo_entidades: []
      },
      fileRules: [(v) => !!v || "El archivo es requerido"],
      fileOld: {},
      tipo_entidades: [],

      menu: false,
      showDialog: false,
      snackbar: false,
      text: "Registro Insertado",
      color: "success",
      timeout: 4000,
      search: "",
      title: title(),
      route: "archivos",
      titlePerms: title().toLowerCase(),

      headers: [
        { text: "Nombre", value: "titulo" },
        { text: "Fecha Publicación", value: "created_at", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      desserts: [],
      filters: {},
      filterKey: [],
      selectedHeaders: [],

      visibleValue: 1
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : this.vista === false
          ? "Editar " + this.title
          : this.title;
    },
    filteredData() {
      return this.$filteredData().data !== undefined
        ? this.$filteredData().data
        : this.$filteredData();
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$close();
      } else {
        if (this.editedItem.id) {
          this.editedIndex = this.editedItem.id;
        } else {
          this.editedIndex = -1;
        }
        this.editedItem.activo = this.editedItem.activo == "1" ? 1 : 0;
      }
    },
  },
  created() {
    this.$initialize(true);
    //this.frontSetGrid();
    if (this.$can([this.titlePerms + "_create"])) {
      this.$getData(["tipo_entidades"]);
    }
    this.selectedHeaders = this.headers;
  },
  methods: {
    nuevoArchivo() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.fileOld = {};
      this.editedIndex = -1;
      this.vista = false;
      this.dialog = true;
    },
    async editItem(id) {
      Swal.alertGetInfo("Obteniendo información <br><b></b>");
      this.editedIndex = id;

      try {
        const r = await this.$axiosApi.getById(this.route, id);
        this.editedItem = Object.assign({}, r.data.data);

        if (!this.editedItem.archivo.name) {
          this.editedItem.archivo.name = "";
        } else {
          this.fileOld = Object.assign({}, this.editedItem.archivo);
        }
        this.dialog = true;
        Swal.close();
      } catch (error) {
        Swal.close();
        this.snackbar = true;
        this.text =
          "<p>Ocurrió un problema al recuperar el registro.<br><hr><b>Codigo:</b>" +
          error.data.code +
          " - <b>Error:</b> " +
          error.data.message +
          "</p>";
        this.color = "error";
      }
    },
    async saveWithFile() {
      if (this.$refs.form.validate() && this.valid) {
        Swal.alertGetInfo(
          this.editedIndex !== -1
            ? "Actualizando información"
            : "Registrando información"
        );

        let fd = new FormData();

        const file = this.editedItem.archivo;
        const maxFileSize = 5 * 1024 * 1024; // 5 MB

        if (this.fileOld.name != file.name) {
          if (file && file.name !== "" && typeof file.name !== "undefined") {
            if (file.size > maxFileSize) {
              this.snackbar = true;
              this.text = "El tamaño del archivo debe ser menor o igual a 5 MB.";
              this.color = "error";
              Swal.close();
              return;
            }
            fd.append("archivo", file, file.name);
          } else {
            this.snackbar = true;
            this.text = "No se registró un archivo adjunto";
            this.color = "error";
            Swal.close();
            return;
          }
        }
        fd.append("titulo", this.editedItem.titulo);
        fd.append("activo", this.editedItem.activo);
        fd.append(
          "tipo_entidades",
          JSON.stringify(this.editedItem.tipo_entidades)
        );

        try {
          if (this.editedIndex != -1) {
            const response = await this.$axiosApi.putFile(
              this.route,
              fd,
              this.editedIndex
            );
            this.$respuesta(this, response, 2);
          } else {
            const response = await this.$axiosApi.postFile(this.route, fd);
            this.$respuesta(this, response, 1);
          }
        } catch (e) {
          this.$respuesta(this, e, this.editedIndex != -1 ? 2 : 1, true);
        } finally {
          Swal.close();
        }
      }
    },
    async removeFile(id) {
      if (id > 0) {
        Swal.fire({
          title: "¿Estas seguro que deseas eliminar el documento seleccionado?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.alertGetInfo("Actualizando información");

            this.$axiosApi
              .delete("archivos_removefile", id)
              .then((r) => {
                this.editedItem.archivo = {};
                this.snackbar = true;
                this.text = "Archivo borrado con éxito";
                this.color = "success";

                Swal.close();
                this.$initialize();
              })
              .catch((e) => {
                this.snackbar = true;
                this.text = "No se pudo borrar el archivo";
                this.color = "error";
                Swal.close();
              });
          }
        });
      } else {
        this.editedItem.archivo = {};
      }
    },

    download(item, descargar = false) {
      if (!JSON.parse(item.archivo).name) {
        Swal.fire("Atención!", "No hay archivos cargados 1", "warning");
        return;
      }

      if (typeof item.archivo === "undefined") {
        Swal.fire("Atención!", "No hay archivos cargados 2", "warning");
        return;
      }

      this.$axiosApi
        .getById("archivos_getfile", item.id)
        .then((response) => {
          const data = response.data;
          if (data.fileContent) {
            const binaryString = window.atob(data.fileContent);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], {
              type: JSON.parse(item.archivo).type,
            });
            const fileURL = window.URL.createObjectURL(blob, {
              oneTimeOnly: true,
            });

            const fileLink = document.createElement("a");
            fileLink.href = fileURL;

            if (descargar) {
              fileLink.setAttribute("download", JSON.parse(item.archivo).name);
            } else {
              fileLink.target = "_blank";
            }
            document.body.appendChild(fileLink);
            fileLink.click();
          } else {
            console.error("No se pudo obtener el archivo.");
            Swal.fire("Atención!", data.message || "No se pudo obtener el archivo.", "warning");
          }
        })
        .catch((error) => {
          console.error(error);
          let message = "Error al obtener el archivo.";
          if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message;
          }
          Swal.fire("Error!", message, "error");
        });
    },
    deleteItem(id, nombre) {
      let vm = this;
      vm.$confirm({
        message: "¿Esta seguro que desea eliminar el documento seleccionado?",
        button: {
          no: "Cancelar",
          yes: "Eliminar",
        },
        callback: async function (confirm) {
          if (confirm) {
            Swal.alertGetInfo("Eliminando Registro");

            await vm.$axiosApi
              .delete(vm.route, id)
              .then((r) => {
                vm.$respuesta(vm, r, 0);
              })
              .catch((e) => {
                vm.$respuesta(vm, e, 0, true);
              });
            Swal.close();
          }
        },
      });
    },
    $enabledItem(item) {
    },
  },
  mounted() {
    console.log("Componente " + this.route + " creado");
  },
};
</script>

<style>
.custom-striped-table .v-data-table__wrapper table tbody tr:nth-child(odd) {
  background-color: #f5f5f5 !important;
}
.custom-striped-table .v-data-table__wrapper table tbody tr:nth-child(even) {
  background-color: #ffffff !important;
}

.text-fields-row {
  display: flex;
}
.x-btn-grid {
  flex: unset;
  background-color: bisque;
  margin: 0 0px;
}

/* Ajustar z-index de SweetAlert2 */
.swal2-container {
  z-index: 999999 !important;
}
.swal2-content {
  z-index: 999999 !important;
}
</style>
