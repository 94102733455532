<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-row>
        <v-col>
          <v-expansion-panels class="filtros-trans-container">
            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Filtros
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <hr>
                <br/>
                <v-row class="">
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="formSearch.ref_pedido"
                      label="Ref pedido"
                      single-line hide-details
                      outlined
                      clearable
                      filled
                      dense
                      rounded
                      class="filtros-trans"
                      @click:append="clearField('ref_pedido')"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-select
                      v-model="formSearch.ente"
                      :items="entidades"
                      label="Ente"
                      class="filtros-trans"
                      outlined
                      filled
                      dense
                      rounded
                      :append-icon="formSearch.ente ? 'mdi-close' : 'mdi-menu-down'"
                      @click:append="clearEntidad"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedStartDate"
                          label="Fecha inicio"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="filtros-trans"
                          outlined
                          filled
                          dense
                          rounded
                          @click:append="clearStartDate"
                          :append-icon="startDate ? 'mdi-close' : 'mdi-menu-down'"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        :max="today"
                        no-title
                        scrollable    
                        locale="es"                                            
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!--
                  <v-col cols="12" md="6">
                    <v-date-picker
                      v-model="startDate"
                      label="Fecha de inicio"
                    ></v-date-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-date-picker
                      v-model="endDate"
                      :min="startDate"
                      label="Fecha final"
                    ></v-date-picker>
                  </v-col>
                  -->
                  <v-col cols="12" sm="2">
                    <v-menu ref="endDateMenu" v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedEndDate"
                          label="Fecha fin"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="filtros-trans"
                          outlined
                          filled
                          dense
                          rounded
                          @click:append="clearEndDate"
                          :append-icon="endDate ? 'mdi-close' : 'mdi-menu-down'"
                          
                          ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        :max="maxDate"
                        :min="startDate"
                        no-title
                        scrollable
                        locale="es"
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="2" class="d-flex align-center">
                    <v-btn
                      color="primary"
                      small
                      @click="fetchTransactions()"
                        >Buscar
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <br>
                <br>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" md="12" class="">
        <v-data-table
          :headers="showHeaders"
          :items="filteredData"
          :search="search"
          sort-by="secret"
          class="elevation-1"
        >
          <template v-slot:no-data>
            <div v-if="loading" class="text-center">
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="mx-auto"
              ></v-progress-circular>
            </div>
            <div v-else>
              Aún no hay datos para mostrar. Realiza una búsqueda utilizando los campos anteriores<v-icon right>mdi-magnify</v-icon>
            </div>
          </template>
          <!--
          <template v-slot:[`header.U_RefPedido`]="{ header }">
            <filter-component
              v-bind:header="{ header }"
              v-bind:filters="filters"
            ></filter-component>
          </template>

          <template v-slot:[`header.estado`]="{ header }">
            <filter-component
              v-bind:header="{ header }"
              v-bind:filters="filters"
            ></filter-component>
          </template>

          <template v-slot:[`header.CardName`]="{ header }">
            <filter-component
              v-bind:header="{ header }"
              v-bind:filters="filters"
            ></filter-component>
          </template>

          <template v-slot:[`header.ente`]="{ header }">
            <filter-component
              v-bind:header="{ header }"
              v-bind:filters="filters"
            ></filter-component>
          </template>
          -->
          <template v-slot:[`item.U_RefPedido`]="{ item }">
            <v-row>
              <v-col  class="table-cell-trans text-uppercase">
                <span @click="viewPedido(item)" class="underline-text">{{ item.U_RefPedido }}</span>
              </v-col>
              
            </v-row>
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-row>
              <v-col>
                <div :class="$getCssTransacciones(item.estado)" class="table-cell-trans">
                  {{ item.estado }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.CardName`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans text-uppercase">
                {{ item.CardName }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.ente`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans text-uppercase">
                {{ item.ente }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.DocTotal`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans">
                {{ (item.DocTotal)? '$'+$formatCurrencyValue(item.DocTotal):item.DocTotal }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.U_MPago`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans text-uppercase">
                {{ item.U_MPago }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.DocDate`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans">
                {{ (item.DocDate)? $formatDate(item.DocDate) :''}}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-center align-center">
              <div style="width: 25px; display: flex; justify-content: center;">
                  <v-icon
                    v-if="item.Drafts && item.Drafts.length > 0"
                    small color="warning" title="Ver Borrador" @click="viewFactura(item, 'borrador')">
                    mdi-file-document
              </v-icon>
              </div>
              <div style="width: 25px; display: flex; justify-content: center;">
                <v-icon
                  v-if="item.Invoices && item.Invoices.length > 0"
                  small color="primary" title="Ver Factura" @click="viewFactura(item, 'borrador')">
                  mdi-file-document
                </v-icon>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Transacciones";
}
import { mapState, mapActions } from "vuex";
export default {
  data: (vm) => ({    
    valid: true,    
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    route: "transacciones",
    today: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
    search: "",
    users: false,
    headers: [
      { text: "Ref Pedido", filterable: true, value: "U_RefPedido", sortable: false },
      { text: "Estado", filterable: true, value: "estado", sortable: false },
      { text: "Cliente", filterable: true, value: "CardName", sortable: false },
      { text: "Ente", filterable: true, value: "ente", sortable: false },
      { text: "Totales", filterable: true, value: "DocTotal", sortable: false },
      { text: "Métodos de pago", filterable: true, value: "U_MPago", sortable: false },
      { text: "Fecha", filterable: true, value: "DocDate", sortable: false },
      { text: "Acciones", value: "actions", sortable: false },
      
    ],

    desserts: [],
    editedIndex: -1,    
    defaultItem: {},
    filters: {
      ref_pedido: "",
      ente: "",
      startDate: "",
      endDate: "",
      delegation: "",
    },
    filterKey: [],
    selectedHeaders: [],
    entidades: [
      {text: "ACARA", value: process.env.VUE_APP_DB_ACARA},
      {text: "MJ", value: process.env.VUE_APP_DB_MJ},      
    ],
    startDateMenu: false,
    endDateMenu: false,
    maxDate:new Date().toISOString().substr(0, 10),
    loading: false,
    queryString:'',
    isAnAd:false,
    formSearch:{},
    filters: {
      ref_pedido: "",
      entidad: "",
      startDate: "",
      endDate: "",
      delegation: "",
    },
    entesDefault:[],
    startDate: null, // Fecha de inicio seleccionada
      endDate: null,   // Fecha de fin calculada
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : "Editar " + this.title;
    },
    
    filteredData() {
      return this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
    formattedStartDate() {
      return this.startDate ? this.$formatDate(this.startDate) : "";
    },
    formattedEndDate() {
      
      return this.endDate ? this.$formatDate(this.endDate) : "";
    },
  },

  watch: {
    startDate(newStartDate) {
      if (newStartDate) {
        const start = new Date(newStartDate);
        start.setDate(start.getDate() + 10);
        let fechaFinal = start.toISOString().slice(0, 10); // Formatea como 'YYYY-MM-DD'        
        this.endDate = fechaFinal
        this.maxDate = fechaFinal;
      } else {
        this.endDate = null; // Si no hay fecha de inicio, limpia endDate
      }
    },
  },

  mounted() {
    console.log("Componente " + this.title + " creado");
  },

  async created() {
  try {    
    // Verifica si sapProperties está vacío
    if (Object.values(this.$store.state.user.sapProperties).length === 0) {
      // Realiza la acción de inicio de sesión
      this.signIn();
      // Espera 3 segundos antes de proceder
      await this.$delay(3000);
    }

    // Llama a validateUserAnmacAduana y procesa la respuesta
    let response = await this.validateUserAnmacAduana();
    this.entesDefault = [process.env.VUE_APP_DB_MJ, process.env.VUE_APP_DB_ACARA]; 
    if (response) {
      this.isAnAd = response;
      // Asigna la delegación si está disponible
      this.formSearch.delegation = (this.$store.state.user.sapProperties.U_Deleg)?this.$store.state.user.sapProperties.U_Deleg:null;      
      // Configura entidades si la respuesta es ANMAC
      if (response === 1) {
        this.entidades = [
          { text: "ANMAC", value: process.env.VUE_APP_DB_ANMAC },
        ];
        this.entesDefault = [process.env.VUE_APP_DB_ANMAC,]; 
      }
    }

    // Configura encabezados seleccionados
    this.selectedHeaders = this.headers;
  } catch (error) {
    console.error("Error en created():", error);
  }
},

  methods: {    
    ...mapActions({
      signIn: "login",      
    }),
    viewPedido(item){
      console.log('mostrar detalle del pedido');
      console.log(item);
    },
    async validateUserAnmacAduana() {           
      const isAduanaAnmac = () => {
        if (this.$isEnabledProperty(this.$ANMAC)) {
          return 1;          
        } else if (this.$isEnabledProperty(this.$ADUANA)) {
          return 2;          
        } else {
          return false;
        }
      };

      let response = await isAduanaAnmac(); 
      
      return response;            
    },
    fetchTransactions(){
      let queryParams = {
        CardCode: this.$store.state.user.username,
        skip: 0,
        Ente:'',
        delegation:''
      }    
      console.log(this.isAnAd);                  
      if(this.isAnAd){        
        if(this.formSearch.delegation) {        
          queryParams.delegation = this.formSearch.delegation;
        }
        this.route = "transaccionesByUser";
      }
      
      queryParams.Ente = (this.formSearch.ente)?this.formSearch.ente:this.entesDefault.join(',');      
      //Filtros
     
        if(this.formSearch.ref_pedido) {          
          queryParams.U_RefPedido = this.formSearch.ref_pedido;
        } else {
          let fechaInicio  = this.startDate;
          let fechaFinal = this.endDate;
          //si no recibo como parametros, las fechas, tomo los ultimos 10 dias
          if(!(this.startDate && this.endDate)){
            fechaInicio = this.today;
            const start = new Date(this.today);
            start.setDate(start.getDate() - 10);
            fechaFinal = start.toISOString().slice(0, 10); 
          }
          queryParams.DocDateFrom = fechaInicio || "";
          queryParams.DocDateTo = fechaFinal || "";
          queryParams.Ente = this.formSearch.ente && typeof this.formSearch.ente === 'string' && this.formSearch.ente.length > 0 ? this.formSearch.ente : this.entesDefault.join(',');
        }
            
      this.queryString = new URLSearchParams(queryParams).toString();

      this.$initializeByQuery(true);
    },
    filterByRef_pedido(item) {
      return this.$filterBy(item, "ref_pedido");
    },
    filterByEstado(item) {
      return this.$filterBy(item, "estado");
    },
    filterByTipo(item) {
      return this.$filterBy(item, "tipo");
    },
    filterByEnte(item) {
      return this.$filterBy(item, "ente");
    },
    
    filterByEndDate(item) {
      return this.$filterBy(item, "endDate");
    },
    filterByStartDate(item) {
      return this.$filterBy(item, "startDate");
    },
    filterByTotal(item) {
      return this.$filterBy(item, "total");
    },
    
    
    clearStartDate() {
      this.startDate = "";
    },
    clearEndDate() {
      this.endDate = "";
    },
    clearEntidad() {
      this.formSearch.ente = null;
    },
    clearField(field) {
      this.filters[field] = "";
    },
  },
  mounted() {
    console.log("Componente " + this.title + " creado");
  },
};
</script>

<style scoped>
.underline-text {
  text-decoration: underline;
  cursor: pointer;
}
</style>