    export default {

      install(Vue, options) {


          Vue.prototype.$cv = function ( value) {
            //let client = this.$keycloak.tokenParsed.foo_tenants[0];
            //let client = this.$keycloak.tokenParsed.tenantId;

            /*
            var vars = {
              iveco:{

                primary:'#54e920',
                principal:"green",
                btnEditar:"orange",
                btnActivo:"blue",
                btnInactivo:"red",
                btnEliminar:"red",
                btnVista:'primary',
                titleActivar:"Activar",
                titleDesactivar:"Desactivar",
                filterColor:"grey-lighten-2"
              },
              flota:{
                primary:'#ff9800',
                principal:"orange",
                btnEditar:"orange",
                btnActivo:"orange",
                btnInactivo:"orange",
                btnEliminar:"orange",
                btnVista:'orange',
                titleActivar:"Activar",
                titleDesactivar:"Desactivar"

              },
              acara:{
                primary:'#011F5B',
                color:"#6DB2D9",
                principal:"primary",
                btnEditar:"primary-2",
                btnActivo:"blue",
                btnInactivo:"red",
                btnEliminar:"red",
                btnVista:'primary',
                titleActivar:"Activar",
                titleDesactivar:"Desactivar",
                filterColor:"grey-lighten-2"
              }
            }*/
            var vars = {
              //primary:'#011F5B',
              color:"#6DB2D9",
              /*
              principal:"primary",
              btnEditar:"orange",
              btnActivo:"blue",
              btnInactivo:"red",
              btnEliminar:"red",
              btnVista:'primary',
              */
              titleActivar:"Activar",
              titleDesactivar:"Desactivar",
              titleArchivar:"Archivar",
              titleDesarchivar:"Desarchivar",
              filterColor:"grey-lighten-2",
              //secondary:'#3d85c6',

              //Design System Colours
              primary: "#1976D2",
              secondary: "#424242",
              accent: "#82B1FF",
              error: "#FF5252",
              info: "#2196F3",
              success: "#4CAF50",
              warning: "#FFC107",
              background: "#e5f3ff",
              actionPrimary: "#0078d4",
              actionSecondary: "#e5f3ff",
              stateSuspended: "#005a9e",
              btnPrimary: '#0078d4',
              btnSecondary: "#6c757d",
              btnInactive: "#6c757d",
              tableBorder: '#dcdcdc',
              tableBackground: '#dcdcdc',

              //Colores redefinidos en base a DS
              btnEliminar:"error",
              btnVista:"primary",
              btnActivo:"actionPrimary",
              btnEditar:"actionPrimary",

          }

          this.$vuetify.theme.themes.dark.primary  = vars["primary"];
          this.$vuetify.theme.themes.light.primary = vars["primary"];
          this.$vuetify.theme.themes.dark.color    = vars["color"];
          this.$vuetify.theme.themes.light.color   = vars["color"];

          this.$vuetify.theme.themes.light.secondary   = vars["secondary"];

              return vars[value];
          },

          Vue.prototype.$getPersonsTypes = function () {
            return [
              { id: 1, name: "FÍSICA" },
              { id: 2, name: "JURÍDICA" },
            ];
          },

          Vue.prototype.$appName = process.env.VUE_APP_NAME

          Vue.prototype.$COMPRAR_ELEMENTOS_REGISTRALES = [1, 11];
          Vue.prototype.$COMPRAR_ELEMENTOS_ADUANA = [15];
          Vue.prototype.$VENDER_ELEMENTOS_ADUANA = [16]; //pendiente de analisis
          Vue.prototype.$VENDER_ESTAMPILLAS_ANMAC = [21, 22, 23]; //pendiente de analisis
          Vue.prototype.$ADQUIRIR_SALDO_CTA_CTE = [64];
          Vue.prototype.$LISTADO_CTA_CTE = [64];
          Vue.prototype.$INFORME_STOCK_ESTAMPILLAS = []; //pendiente de analisis
          Vue.prototype.$INGRESO_STOCK_ESTAMPILLAS = []; //pendiente de analisis
          Vue.prototype.$INHABILITAR_STOCK_ESTAMPILLAS = []; //pendiente de analisis

          Vue.prototype.$ADUANA = [16]; //props de aduana
          Vue.prototype.$ANMAC = [21, 22, 23]; //props de anmac

          Vue.prototype.$db =function ( value) {

            if(value =="ACARA")
              return process.env.VUE_APP_DB_ACARA;
            if(value =="MJ")
              return process.env.VUE_APP_DB_MJ;
             if(value =="ANMAC")
              return process.env.VUE_APP_DB_ANMAC;

          }
      }
  }


