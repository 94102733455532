import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import AxiosApi from "./plugins/axiosApi"

Vue.use(Vuex);

import carrito from "./modules/carrito";
//import anmac from "./modules/anmac";
//import aduana from "./modules/aduana";
import saldoCC from "./modules/saldoCC";
import transacciones from "./modules/transacciones";

export default new Vuex.Store({
  modules: {
    carrito,
    //anmac,
    //aduana,
    saldoCC,
    transacciones,
  },

  plugins: [createPersistedState()],
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    /*barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',*/
    barImage: "",
    drawer: null,
    keyDrawer:0,
    authenticated: false,
    drawerLoading: true,
    config: [],
    user: {
      id:null,
      username:null,
      name:null,
      roles: Array,
      permisos: Array,
      entidades: Array,
      notificaciones: [],
      sapProperties:{},
      /*carrito: {
        data: [], // Inicializado como un arreglo vacío
        cantItemTotales: 0,
        cantItem: 0,
        total: 0
      }*/
    },
    paymentMethods: [{
      code: 'SALDO',
      desc: "SALDO A FAVOR",
      //paymentkey: c_paymentMethods.saldo,
      no_charge: true,
      methods: [{
        //paymentkey: c_paymentMethods.saldo,
        GLAccount: '1231231',
        desc: 'Saldo a favor',
        PaymentMethodCode: 0,
        code: 'SALDO'
      }]
    },
      {
        code: 'BANKNACION',
        desc: "BOLETA BANCO NACIÓN",
        methods: [
          {
            //paymentkey: c_paymentMethods.cash,
            GLAccount: '2222',
            desc: 'Boleta efectivo Banco Nación',
            no_charge: true,
            PaymentMethodCode: 0,
            code: 'BANKNACION_EFECTIVO'
          },
          {
            //paymentkey: c_paymentMethods.check,
            GLAccount: '3333',
            desc: 'Boleta cheque Banco Nación',
            no_charge: false,
            PaymentMethodCode: 0,
            code: 'BANKNACION_CHEQUE',
            // Se asume que `data.customer_info` ya está definido
            // noDisplay: data.customer_info.is_deleg_anmac
          }
        ]
      },
      {
        code: 'BTOB',
        desc: "B TO B",
        //paymentkey: c_paymentMethods.btob,
        no_charge: true,
        methods: [{
          //paymentkey: c_paymentMethods.btob,
          GLAccount: '1231231',
          desc: 'B to B (Transferencia bancaria)',
          PaymentMethodCode: 0,
          code: 'BTOB'
        }],
        cartEditPath: null,
      },
    ],
    precioFlete: null, // Estado para almacenar el precio de flete
    conveniosRecaudacion: {},
  },
  mutations: {

    async SET_CONVENIOS_RECAUDACION(state, token) {
      try {
        const axiosApi = new AxiosApi(token);
        const response = await axiosApi.getByCriteria(
          "conveniosRecaudacion",
          "TST_MJV20,TST_ACARAV20"
        );

        if (response.data && response.data.data) {
          state.conveniosRecaudacion = response.data.data;
        } else {
          state.conveniosRecaudacion = {}; // Vaciar si no hay datos
        }
      } catch (error) {
        console.error("Error al obtener convenios de recaudación:", error);
        state.conveniosRecaudacion = {}; // Vaciar en caso de error
      }
    },

    async SET_FETCH_PRECIO_FLETE(state, token) {
      try {
        const axiosApi = new AxiosApi(token);
        const response = await axiosApi.getByCriteria("itemsByCode", "FLE/"+process.env.VUE_APP_DB_ACARA);

        if (response.data && response.data.data && response.data.data.value) {
          const itemPrices = response.data.data.value[0].ItemPrices;
          if(typeof state.user.sapProperties.PriceListNum !=='undefined'){
            const userPriceListNum = (typeof state.user.sapProperties.PriceListNum !=='undefined')? state.user.sapProperties.PriceListNum:0;
            const shippingItem = itemPrices.find(
              (price) => price.PriceList === userPriceListNum.toString()
            );
            if (shippingItem) {
              const precio = parseFloat(shippingItem.Price);
              state.precioFlete = precio;
            } else {
              state.precioFlete = null;
            }
          }
          else{
            state.precioFlete = null;
          }
          

          
        }
      } catch (error) {
        console.error("Error al obtener el precio de flete:", error);
        state.precioFlete = null;
      }
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_BAR_COLOR(state, payload) {
      state.barColor = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    async SET_USER (state, payload){
      try{
        let userId = payload.tokenParsed.sub;
        state.user.id = userId;
        state.user.username = payload.tokenParsed.preferred_username;
        state.user.name = payload.tokenParsed.given_name +
        " " +
        payload.tokenParsed.family_name;
        const client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
        if(payload.tokenParsed.groups){
          const roleUser = payload.tokenParsed.groups.filter(item => item.substring(item.indexOf(' - ')+3) == client)
          .map(item => item.substring(1, item.indexOf(' - ')));
          state.user.roles = roleUser;
        }
        else{
          state.user.roles = [];
        }
        state.user.permisos = [];
        //console.log("state.user.permisos");
        //console.log(payload);
        if(payload.resourceAccess){
          for (const key in payload.resourceAccess) {
              if(key == client){
                state.user.permisos = payload.resourceAccess[key].roles;
                break;
              }
          }
        }
        else{
          state.user.permisos = [];
        }
        //busco la/s entidad/es
        if(state.user.entidades.length < 1)
        {
            let axiosApi = new AxiosApi(payload.token)
            axiosApi.getByCriteria('usuarios_entidades', userId+'/'+client)
            .then((r) => {
              if(r.data.data){

                let arrayEntidades = r.data.data;
                let entidades = [];
                for (const k in arrayEntidades) {

                  entidades.push(arrayEntidades[k].entidad_id);

                }
                state.user.entidades = entidades;
              }
              else{
                state.user.entidades = []
              }

            })
            .catch(function (error) {
              console.log(error);
            });
        }
        //datos de usuarios
        let axiosApiUser = new AxiosApi(payload.token)
        axiosApiUser.getByCriteria('usuarios', userId)
            .then((r) => {
              if(r.data.data){
                 state.user.userInfo = r.data.data;
              }

            })
            .catch(function (error) {
              console.log(error);
            });

        //state.user.notificaciones = [];
        let axiosApi = new AxiosApi(payload.token)
            axiosApi.getByCriteria('notificacionesByUser', userId)
            .then((r) => {
              if(r.data.data){
                state.user.notificaciones = r.data.data;
              }
              else{
                state.user.notificaciones = []
              }
            })
            .catch(function (error) {
              console.log(error);
            });

            return 1;
      }
      catch(error){
        return error;
      }


    },
    async SET_DATA_SAP(state, payload){
      try{


      if(typeof state.user.sapProperties.CardCode == 'undefined'  )
        {
            state.drawerLoading = true;
            const respSap = async (dataUser) => {
              let username = dataUser.tokenParsed.preferred_username;

              //let params = username.toUpperCase() + "/TST_MJV20";
              let params = username.toUpperCase();

              let axiosApiUser = new AxiosApi(payload.token)
              const response = await axiosApiUser.getByCriteria(
                'bussinessPartnerClient',
                params
              );

              return response.data.data;
            };
            let resp = await respSap(payload);
            if (resp) {
              console.log(resp);
              state.user.sapProperties = resp;

              state.keyDrawer = + 1;


            } else {
              state.user.sapProperties = {};
              state.keyDrawer = + 1;


            }

            state.drawerLoading = false;
        }
        else{
             state.drawerLoading = false;

        }
      }
      catch (error) {
        console.log(error);
      }

    },
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_CART_EDIT_PATH(state, value) {
      state.cartEditPath = value;
    },
    RESET_USER (state){
      state.user.id = null;
      state.user.username = null,
      state.user.name = null;
      state.user.roles =[];
      state.user.permisos = [];
      state.user.entidades = [];
      state.user.notificaciones = [];
      state.user.userInfo = [];
      state.drawerLoading= true;
      state.user.sapProperties={};
      state.carrito = {
        data: [], // Inicializado como un arreglo vacío
        cantItemTotales: 0,
        cantItem: 0,
        subtotal: 0,
        gastos:0,
        total: 0,
        cliente:{}
      }


    },
    ADD_NOTIFICATION(state, notification) {
      state.user.notificaciones.push(notification);
    },

    async SET_CONFIG (state, payload){
      try{

          let axiosApi = new AxiosApi(payload.token)
          axiosApi.get('configuraciones')
            .then((r) => {
              if(r.data){

                let arrayConfig = r.data.data;
                let config = [];
                for (const item of arrayConfig) {
                  config[item.clave] = item.valor;
                }
                state.config = config;
              }
              else{
                state.config = []
              }

            })
            .catch(function (error) {
              console.log(error);
            });
        }
        catch(error){
          return error;
        }

      }




  },



  actions: {



    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },
    logout({ commit }) {
      commit('RESET_USER',{})
      commit('SET_AUTHENTICATED',false)
      commit('carrito/remove', {})

    },
    async login({ commit }) {
      let client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
      if(Vue.prototype.$keycloak)
      {
        let userId = Vue.prototype.$keycloak.tokenParsed.sub;

          await commit('SET_DATA_SAP',Vue.prototype.$keycloak);
          await commit('SET_USER',Vue.prototype.$keycloak);
          await commit('SET_CONFIG',Vue.prototype.$keycloak);
          commit('SET_AUTHENTICATED',true);

          // Llamada para obtener el precio de flete después de autenticar al usuario
          await commit("SET_FETCH_PRECIO_FLETE", Vue.prototype.$keycloak.token);
          await commit("SET_CONVENIOS_RECAUDACION", Vue.prototype.$keycloak.token);

          return 1;
      }else{
        return 0;
      }
      
    },



  },
  getters: {
    isLogged: (state) => !!state.user,
    authenticated(state) {
      return state.authenticated;
    },
    getPaymentMethods(state) {
      const options = state.paymentMethods.flatMap(method => {
        return method.methods.map(subMethod => ({
          code: subMethod.code,
          desc: subMethod.desc
        }));
      });

      // Devuelve ordenado alfabéticamente
      return options.sort((a, b) => a.desc.localeCompare(b.desc));
    },
    getPrecioFlete(state) {
      return state.precioFlete; // Para obtener el precio de flete
    },
  },
});
