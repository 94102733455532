<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-btn
        class="ml-6 mt-6"
        dark
        elevation="0"
        fab
        icon
        x-large
        :style="{ pointerEvents: 'none', background: `${color}45` }"
      >
      <v-icon
          color="warning"
          class="text-center"
          style="font-size: 55px;pointer-events: none;"
        >
          mdi-alert-circle-outline
        </v-icon>
      </v-btn>
      <v-card-title class="headline">
        <p class="text-left" style="font-weight: 500; font-size: 18px;">
          ¿Estás seguro de que deseas guardar los <br>cambios realizados?
        </p>
      </v-card-title>

      <v-card-text>
        <p style="font-weight: 400; color:  #7D7D7D;">

        </p>
      </v-card-text>

      <v-card-actions style="background: #F1F1F1;" class="pt-4 pb-4">

        <v-btn width="47%" color="white" @click="cancel" class="capitalize-first custom-btn">
          Cancelar
        </v-btn>
        <v-btn width="47%" :color="color" dark @click="confirm"  class="capitalize-first custom-btn">
          Sí, estoy seguro
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {

    value: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      color: '#ffc107',
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    confirm() {
      this.$emit('close', { confirmed: true });
      this.dialog= false;
    },
    cancel() {
      this.$emit('close', { confirmed: false });
      this.dialog = false;
    },

  },
};
</script>
<style scoped>
.custom-btn {
  text-transform: none; /* Elimina la transformación de mayúsculas */
  font-family: inherit; /* Hereda el tipo de letra por defecto */
}

.capitalize-first::first-letter {
  text-transform: capitalize; /* Solo la primera letra en mayúscula */
}
</style>
