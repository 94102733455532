<template>
    <v-container id="dashboard" fluid tag="section" style="padding-right: 0px">

        <div class="center" style="margin-top: 5%;" >
          <v-card class="custom-card elevation-0" >
            <v-row>
              <v-col  cols="12" md="7" style="text-align: left;padding-top: 100px;" class="bg-secondary">
                <p class="ml-6"><b style="font-size: 1.5rem;" >Bienvenidos a portal!</b></p>

                <div class="ml-6" style="width:90px;height: 7px;background-color: #fff;"></div>
                <br>
                <p class="ml-6">Gestiona todos tus trámites automotores en un solo lugar,<br>
                de forma rápida y segura. <b>¡Todo lo que necesitas está aquí!</b></p>
              </v-col>
              <v-col  cols="12" md="5"  class="bg-secondary" style="text-align: right; ">

                <img width="400" height="400" src="/img/acara_logo_2.png" style="text-align: right; background-color: #fff;border-radius: 50% 0 0 50%; padding: 3%; margin:-2%"/>
              </v-col>
            </v-row>

          </v-card>
        </div>
    </v-container>
</template>

<script>
	export default {};
</script>

<style scoped>
.custom-card {
  border-radius: 20px 0 0 20px;
  overflow: hidden;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1) !important;

}


</style>
