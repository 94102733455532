<template>
  <v-app>
    <v-container fluid fill-height>
      <v-row
        justify="center"
        align="center"
        style="background: #eee"
        class="pa-0 mt-0"
      >
        <v-col
          cols="12"
          md="12"
          lg="12"
          style="background: #eee"
          class="pt-0 pa-0"
        >
          <v-row
            style="background-color: rgb(139 139 139 / 15%)"
            class="pa-4 ml-0 mr-0"
            ><v-col cols="12" sm="1" style="margin-right: -14px" class="pa-0"
              ><v-img src="/img/acara_logo_2.png" width="80"
            /></v-col>
            <v-col
              cols="12"
              sm="11"
              style="margin-right: -14px"
              class="pa-3 pt-5 ml-4"
              ><h1 style="font-size: 32px; font-weight: bold">
                Solicitud de Acceso al Portal para Nuevos Concesionarios
              </h1></v-col
            ></v-row
          ></v-col
        >
        <v-col
          cols="12"
          md="12"
          lg="10"
          style="background: #eee"
          class="mt-4 pa-0"
        >
          <v-card
            class="pa-0 pl-3 pr-3 elevation-0 mt-0"
            style="background: #eee"
          >
            <v-form ref="solicitudUsuarioForm" v-model="valid" lazy-validation>
              <v-toolbar dark color="primary" class="mt-4">
                <v-toolbar-title>Datos del Concesionario</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-card-text class="elevation-1" style="background: #fff">
                <v-row class="pt-2">
                  <v-col cols="12" md="1" sm="12" class="pa-0 ml-6">
                    <v-img class="icon-size" :src="avatar"></v-img>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="pa-0 pl-4 pt-4">
                    <v-text-field
                      v-model="editedItem.cuitcuil"
                      label="CUIT/CUIL"
                      hint="Ingrese solo números"
                      maxLength="13"
                      class="input-h1"
                      v-mask="'##-########-#'"
                      prepend-inner-icon="mdi-card-account-details"
                      :rules="[$rulesRequerido, ruleUniqueCuit]"
                      :error-messages="customErrorMessage"
                      @input="updateUserName"
                      ref="cuitField"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" class="pa-0 pl-4 pt-4">
                    <v-text-field
                      v-model="editedItem.razon_social"
                      label="Razón Social"
                      class="input-h1"
                      prepend-inner-icon="mdi-card-account-details"
                      :rules="[$rulesRequerido]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" sm="12" class="pa-0 pl-4"></v-col>
                </v-row>
                <v-row class="pb-3">
                  <v-col cols="12" md="9" sm="12" class="py-0">
                    <v-toolbar dark color="primary" class="mt-4">
                      <v-toolbar-title>Dirección</v-toolbar-title>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        color="white"
                        outlined
                        x-small
                        title="Del punto de entrega pedidos"
                      >
                        <v-icon
                          title="Del punto de entrega pedidos"
                          class="mx-2"
                          fab
                          dark
                          color="white"
                          outlined
                          small
                        >
                          mdi-help
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text
                      class="elevation-1 pb-2"
                      style="background: #fff"
                    >
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col cols="12" md="4" sm="12" class="py-0">
                              <v-autocomplete
                                :items="provincias"
                                item-value="id"
                                item-text="nombre"
                                label="Provincia"
                                v-model="editedItem.datos_entidad.provincia"
                                :rules="[$rulesRequerido]"
                                @change="
                                  getPartidos(
                                    editedItem.datos_entidad.provincia.id
                                  )
                                "
                                return-object
                              ></v-autocomplete
                            ></v-col>
                            <v-col cols="12" md="4" sm="12" class="py-0">
                              <v-autocomplete
                                :items="partidos"
                                item-value="id"
                                item-text="nombre"
                                label="Partido"
                                v-model="editedItem.datos_entidad.partido"
                                :rules="[$rulesRequerido]"
                                @change="
                                  getCiudades(
                                    editedItem.datos_entidad.partido.id
                                  )
                                "
                                return-object
                              ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" md="4" sm="12" class="py-0">
                              <v-autocomplete
                                :items="ciudades"
                                item-value="id"
                                item-text="nombre"
                                label="Localidad"
                                v-model="editedItem.datos_entidad.ciudad"
                                :rules="[$rulesRequerido]"
                                return-object
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="8" sm="12" class="py-0">
                              <v-text-field
                                v-model="editedItem.datos_entidad.calle"
                                label="Calle"
                                :rules="[$rulesRequerido, $rulesMax50]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="12" class="py-0">
                              <v-text-field
                                v-model="editedItem.datos_entidad.numero"
                                label="Numero"
                                :rules="[
                                  $rulesRequerido,
                                  $rulesEnterosPositivos,
                                  $rulesMax6,
                                ]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="4" sm="12" class="py-0">
                              <v-text-field
                                v-model="editedItem.datos_entidad.piso"
                                label="Piso"
                                :rules="[$rulesEnterosPositivos, $rulesMax6]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="12" class="py-0">
                              <v-text-field
                                v-model="editedItem.datos_entidad.depto"
                                label="Depto"
                                :rules="[$rulesMax6]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="12" class="py-0">
                              <v-text-field
                                v-model="editedItem.datos_entidad.cp"
                                label="Código Postal"
                                :rules="[
                                  $rulesEnterosPositivos,
                                  $rulesNumericoMax10,
                                  $rulesRequerido,
                                ]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-0">
                    <v-toolbar dark color="primary" class="mt-4 elevation-1">
                      <v-toolbar-title>Contacto</v-toolbar-title>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        color="white"
                        outlined
                        x-small
                        title="Del punto de entrega pedidos"
                      >
                        <v-icon title="Del punto de entrega" small dark>
                          mdi-help
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text
                      class="elevation-1 pb-2"
                      style="background: #fff"
                    >
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col cols="12" md="12" sm="12" class="py-0">
                              <v-text-field
                                v-model="editedItem.datos_entidad.telefono"
                                label="Telefono"
                                :rules="[$rulesRequerido, $rulesTelefono]"
                                maxlength="20"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12" class="py-0">
                              <v-text-field
                                v-model="editedItem.datos_entidad.celular"
                                label="Celular"
                                :rules="[$rulesRequerido, $rulesTelefono]"
                                maxlength="20"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12" class="py-0">
                              <v-text-field
                                v-model="editedItem.datos_entidad.mail"
                                label="Email"
                                :rules="[$rulesRequerido, $rulesMail]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-toolbar dark color="primary" class="mt-4">
                <v-toolbar-title>Datos Del Usuario</v-toolbar-title>

                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text class="elevation-1" style="background: #fff">
                <v-row
                  :style="{
                    borderRadius: '10px',
                    margin: '4px',
                  }"
                >
                  <v-col>
                    <v-row>
                      <v-col cols="12" md="3" sm="12" class="py-0">
                        <v-text-field
                          v-model="editedItem.datos_usuario.apellido"
                          label="Apellido"
                          :rules="[$rulesRequerido, $rulesAlfaNum, $rulesMax50]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" sm="12" class="py-0">
                        <v-text-field
                          v-model="editedItem.datos_usuario.nombre"
                          label="Nombre"
                          :rules="[$rulesRequerido, $rulesAlfaNum, $rulesMax50]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" sm="12" class="py-0">
                        <v-autocomplete
                          v-model="editedItem.datos_usuario.tipo_documento"
                          :items="identifiertypes"
                          item-text="name"
                          item-value="id"
                          label="Tipo Documento"
                          return-object
                          :rules="[$rulesRequerido]"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" sm="12" class="py-0">
                        <v-text-field
                          v-model="editedItem.datos_usuario.documento"
                          label="Documento"
                          class="input-h1"
                          :rules="[$rulesRequerido, $rulesAlfaNum]"
                          maxlength="9"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3" sm="12" class="py-0">
                        <v-text-field
                          v-model="editedItem.datos_usuario.usuario"
                          label="Nombre de usuario"
                          :rules="[$rulesRequerido]"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" sm="12" class="py-0">
                        <v-text-field
                          v-model="editedItem.datos_usuario.telefono"
                          label="Telefono"
                          :rules="[$rulesRequerido, $rulesTelefono]"
                          maxlength="20"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" sm="12" class="py-0">
                        <v-text-field
                          v-model="editedItem.datos_usuario.celular"
                          label="Celular"
                          :rules="[$rulesRequerido, $rulesTelefono]"
                          maxlength="20"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" sm="12" class="py-0">
                        <v-text-field
                          v-model="editedItem.datos_usuario.mail"
                          label="Correo"
                          :rules="[
                            $rulesRequerido,
                            $rulesMail,
                            ruleUniqueEmail,
                          ]"
                          :error-messages="customErrorEmailMessage"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <template>
                  <v-row>
                    <v-col cols="12" sm="12" class="pa-0 pl-4 pr-4">
                      <v-divider
                        class="mt-2 pa-2"
                        :class="
                          Number(editedItem.index) % 2 == 0
                            ? 'border-primary'
                            : 'border-primary-2'
                        "
                      ></v-divider>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
              <br />
              <v-row>
                <v-col cols="12" md="12">
                  <v-alert
                    title="Alert title"
                    text=""
                    type="info"
                    border="left"
                    variant="tonal"
                    prominent
                  >
                    <p>
                      Una vez finalizada la solicitud, la misma estará pendiente
                      de aprobación por parte de
                      <b style="font-weight: bold">ACARA</b>. Toda la
                      información relevante se enviará a la dirección de correo
                      electrónico proporcionada en el formulario. <br />
                      Si tiene algún inconveniente no dude en contactarse al
                      <b style="font-weight: bold">(011) 5236-6500</b> o vía
                      mail a
                      <b style="font-weight: bold">socios@acara.org.ar</b>.
                    </p></v-alert
                  ></v-col
                ></v-row
              >
              <v-card-actions>
                <v-row>
                  <v-col class="text-right" cols="12" md="12" sm="12">
                    <v-btn color="red" @click="exit()">Cancelar</v-btn>
                    <v-btn color="orange" @click="reset()">Limpiar</v-btn>
                    <v-btn color="primary" :disabled="!valid" @click="save()"
                      >Enviar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>

            <v-snackbar
              v-model="snackbar"
              :bottom="true"
              :color="color"
              :timeout="timeout"
            >
              <div v-html="text"></div>

              <template v-slot:action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="snackbar = false">
                  Cerrar
                </v-btn>
              </template>
            </v-snackbar>
            <vue-confirm-dialog></vue-confirm-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <section
      class="contacto"
      style="padding: 12px 0 12px 0; background-color: #0177bd; color: #fff"
    >
      <div class="container">
        <v-row>
          <v-col md="4" xs="12">
            <v-row>
              <v-col md="2"
                ><img
                  class="icon-size-2"
                  src="https://acara.org.ar/img/icon_footer_phone.png"
              /></v-col>
              <v-col md="10">
                <p>Contactate con nosotros<br />(011) 5236 6500</p></v-col
              >
            </v-row>
          </v-col>
          <v-col md="4" xs="12">
            <v-row>
              <v-col md="2"
                ><img
                  class="icon-size-2"
                  src="https://acara.org.ar/img/icon_footer_envelope.png"
              /></v-col>
              <v-col md="10">
                <p>
                  Envianos tus consultas a <br /><a
                    href="mailto:socios@acara.org.ar"
                    style="color: #fff"
                    >socios@acara.org.ar</a
                  >
                </p></v-col
              >
            </v-row>
          </v-col>
          <v-col md="4" xs="12">
            <v-row>
              <v-col md="2"
                ><img
                  class="icon-size-2"
                  src="https://acara.org.ar/img/icon_footer_map.png"
              /></v-col>
              <v-col md="10"
                ><p>Encontranos<br />estés dónde estés</p></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </div>
    </section>
    <footer>
      <div
        class="terminos"
        style="
          padding: 12px 0 12px 0;
          background-color: #011f5b;
          color: #6db2d9;
        "
      >
        <div class="container">
          <div class="row">
            <div class="col-6 text-left ml-2">
              <p style="margin-bottom: 5px">Av Córdoba 3371, CABA</p>
              <p>2024 ACARA - Todos los derechos reservados.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </v-app>
</template>

<script>
function getTitle() {
  return "solicitud usuario";
}

export default {
  data: (vm) => ({
    valid: true,
    route: "solicitudUsuario",
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    editedIndex: -1,
    editedItem: {
      cuit: "",
      razon_social: "",
      estado_id: 1,
      datos_entidad: {},
      datos_usuario: {},
    },
    defaultItem: {
      cuit: "",
      razon_social: "",
      estado_id: 1,
      datos_entidad: {},
      datos_usuario: {},
    },
    provincias: [],
    partidos: [],
    ciudades: [],

    identifiertypes: [],
    paises: [],
    avatar: "/img/user_primary.png",
    formPerson: {},
    routeBussinesPartnersIsClient: "bussinessPartnerIsClient",

    cuitUnique: true,
    keyCuit: 0,
    customErrorMessage: "", // Mensaje de error personalizado
    customErrorEmailMessage: "", // Mensaje de error personalizado
  }),

  computed: {},

  created() {
    this.$cv("colorPrimary");
    this.$getData(["identifiertypes" /*, "paises"*/, "provincias"]);
    //this.setDatosPrueba();
  },

  methods: {
    updateUserName() {
      let cuitcuil = this.editedItem.cuitcuil;
      this.editedItem.datos_usuario.usuario = "C" + cuitcuil.replace(/-/g, "");
      /*this.editedItem.datos_usuario.usuario = this.editedItem.cuit
        ? `${this.editedItem.datos_usuario.usuario.toLowerCase()}@example.com`
        : "";*/
    },

    /*async isClient(value) {
      try {
        if (value) {
          if (value.length == 13) {
            let cuit = value.replace(/-/g, "");
            cuit = "C" + cuit;
            let params = cuit + "/TST_MJV20";

            const response = await this.$axiosApi.getByCriteria(
              this.routeBussinesPartnersIsClient,
              params
            );

            return response.data.data.isClient;
          } else {
            return 0;
          }
        }
      } catch (error) {
        Swal.close();
        return error.response.status;
      }
    },*/

    async ruleUniqueCuit(value) {
      if (typeof value !== "undefined" && value != null && value !== "") {
        this.customErrorMessage = "";
        //let resp = await this.$isClient(value, 'A80', process.env.VUE_APP_DB_MJ);
        let resp = await this.$isClient(value, 'C', process.env.VUE_APP_DB_ACARA);
        switch (resp) {
          case 1:
            this.customErrorMessage = "Este CUIT ya está en uso";
            this.editedItem.datos_usuario.usuario = "";
            return false;

          case -1:
            this.customErrorMessage = "CUIT inválido";
            this.editedItem.datos_usuario.usuario = "";
            return false;

          default:
            this.customErrorMessage = "";
            return true;
        }
        /*
        if (resp == 1) {
          this.customErrorMessage = "Este CUIT ya está en uso";
          this.editedItem.datos_usuario.usuario = "";
          return false;
        } else {
          this.customErrorMessage = "";
          return true;
        }
        */
      } else {
        this.customErrorMessage = "";
      }
    },
    async ruleUniqueEmail(value) {
      if (typeof value !== "undefined" && value != null && value !== "") {
        this.customErrorMessage = "";
        let resp = await this.$isUser(value);
        console.log("resp");
        console.log(resp);
        if (resp === true) {
          this.customErrorEmailMessage =
            "El email ingresado ya está registrado. Por favor, ingrese un email diferente";
          //this.editedItem.datos_usuario.usuario = "";
          return false;
        } else {
          this.customErrorEmailMessage = "";
          return true;
        }
      } else {
        this.customErrorMessage = "";
      }
    },
    setDatosPrueba() {
      //this.editedItem.cuitcuil = "";
      this.editedItem.razon_social = "AUTOFRANCE PEUGEOT";
      this.editedItem.datos_entidad.telefono = "1111111111";
      this.editedItem.datos_entidad.celular = "1556443322";
      this.editedItem.datos_entidad.mail = "mail@mail.com";
      this.editedItem.datos_entidad.pais = { code: "ARG", name: "ARGENTINA" };
      this.editedItem.datos_entidad.provincia = {
        id: "06",
        name: "Buenos Aires",
      };
      this.editedItem.datos_entidad.localidad = "MONTE GRANDE";
      this.editedItem.datos_entidad.cp = "1803";
      this.editedItem.datos_entidad.calle = "ALEM";
      this.editedItem.datos_entidad.numero = "100";
      this.editedItem.datos_usuario.apellido = "APELLIDO PERSONA";
      this.editedItem.datos_usuario.nombre = "NOMBRE PERSONA";
      this.editedItem.datos_usuario.tipo_documento = { id: 4, name: "DNI" };
      this.editedItem.datos_usuario.documento = "30987222";
      this.editedItem.datos_usuario.usuario = "";
      this.editedItem.datos_usuario.telefono = "2051882299";
      this.editedItem.datos_usuario.celular = "1122334455";
      this.editedItem.datos_usuario.mail = "luis.quintero1983@gmail.com";
    },

    reset() {
      //this.$refs.solicitudUsuarioForm.reset();
      //this.editedItem = Object.assign({}, this.defaultItem);
      this.$refs.solicitudUsuarioForm.resetValidation();
      this.$refs.solicitudUsuarioForm.reset();
      this.editedItem = Object.assign({}, this.defaultItem);

      /*this.$nextTick(() => {
																																				console.log(this.editedItem);
																																				this.editedItem = Object.assign({}, this.defaultItem);

																																				this.editedIndex = -1;
																																			});*/

      console.log(this.editedItem);
      //this.resetValidation();
    },
    resetValidation() {
      this.$refs.solicitudUsuarioForm.resetValidation();
    },

    save() {
      console.log(this.$refs.solicitudUsuarioForm.validate());
      if (this.$refs.solicitudUsuarioForm.validate()) {
        this.editedItem.cuit = this.editedItem.cuitcuil.replace(/-/g, "");
        let tituloSwal = "Confirmación";
        let tituloSwalOk = "registraron";
        Swal.fire({
          title: "<b>" + tituloSwal + "?</b>",
          text: "Usted está por enviar una solicitud a ACARA para la creación de un usuario Concesionario",
          icon: "question",
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: true,
          confirmButtonColor: "green",
          cancelButtonColor: "#d33",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Swal.alertGetInfo("Enviando solicitud de usuario");
            this.$axiosApi
              .post(this.route, this.editedItem)
              .then((r) => {
                //console.log(this.editedItem);
                if (
                  typeof r !== "undefined" &&
                  r !== null &&
                  (r.status == 200 || r.status == 201 || r.succeeded == true)
                ) {
                  Swal.close();
                  Swal.fire({
                    title: "Se " + tituloSwalOk + " los datos de la solicitud ",
                    icon: "success",
                    html: "Su solicitud se encuentra en proceso de evaluación",
                    showCloseButton: false,
                    showCancelButton: false,
                    focusConfirm: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar",
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.href = "/";
                      return;

                      //this.$router.push({ path: "/" });
                    }
                  });
                } else {
                  Swal.close();
                  Swal.fire({
                    icon: "error",
                    title: "Solicitud de Usuario",
                    text: "Ha ocurrido un error al intentar registrar los datos de la solicitud",
                  });
                }

                return;
              })
              .catch((e) => {
                Swal.close();
                console.log(e);
                this.snackbar = true;
                this.text = "Error al ingresar los datos. Error: " + e.message;
                this.color = "error";
              });

            //this.$alertGetInfo("Registrando información del tramite");
          }
        });
      }
    },

    async getPartidos(provincia_id) {
      Swal.alertGetInfo("Obteniendo Partidos <br><b></b>");
      this.partidos = [];
      let url = "api/provincias/public/" + provincia_id + "/partidos";

      this.$axiosApi
        .sendRequest(url, "get")
        .then((r) => {
          if (r.data.data) {
            console.log("partidos");
            console.log(r.data.data);
            this.partidos = r.data.data;
            Swal.close();
          }
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
        });
    },
    async getCiudades(partido_id) {
      Swal.alertGetInfo("Obteniendo Ciudades <br><b></b>");
      this.ciudades = [];
      let url = "api/partidos/public/" + partido_id + "/localidades";

      this.$axiosApi
        .sendRequest(url, "get")
        .then((r) => {
          if (r.data.data) {
            console.log("ciudades");
            console.log(r.data.data);
            this.ciudades = r.data.data;
            Swal.close();
          }
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
        });
    },
    exit() {
      window.location.href =
        window.location.protocol + "//" + window.location.host;
    },
  },
  mounted() {
    console.log("Componente " + getTitle() + " creado");
  },
};
</script>
<style>
.container {
  margin-left: 0px;
  padding: 0px;
  max-width: 100% !important;
}
.icon-size {
  width: 80px !important;
  height: 80px !important;
}
.icon-size-2 {
  width: 50px !important;
  height: 50px !important;
}
.bg-gris {
  background-color: #eee;
}
</style>
