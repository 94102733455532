<template>
  <v-container id="crud" fluid tag="section">
    <v-row v-if="busquedaCliente">
      <v-col cols="12" md="12" class="py-0">
        <v-card elevation="0">
          <v-card-title>Busqueda de clientes</v-card-title>
          <br>
          <v-card-text>
              <v-row class="">
                <v-col cols="12" sm="3">
                  <label for="">Cuit o Pasaporte</label>
                  <v-text-field
                  v-model="searchCuit"
                  dense
                  outlined

                  hint="Ingrese cuit o pasaporte completo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <label for="">Nombre o Razon Social</label>
                  <v-text-field
                  v-model="searchName"
                  dense
                  outlined

                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" class="d-flex align-center pt-1">
                  <v-btn
                    color="primary"
                    small
                    class="capitalize-first custom-btn"
                    @click="buscar()"
                      >Buscar
                    <v-icon right>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="2" class="d-flex align-center pt-1">
                  <v-btn
                    color="success"
                    small
                    @click="nuevoCliente()"
                    class="capitalize-first custom-btn"
                      >Nuevo cliente
                    <v-icon right>mdi-account-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12"  class="pt-0">
       <v-data-table
          :headers="headersClientes"
          :items="clientes"
          class="elevation-0 data__table"
          :footer-props="{
            'items-per-page-options': [10, 20],
          }"
          :item-class="getItemClass"
        >



        <template v-slot:no-data>
        <div class="text-center">
          <br><br>
          <v-icon color="primary" style="font-size: 65px;">mdi-magnify</v-icon>
          <p class="mt-2">Aún no hay datos para mostrar. Realiza una búsqueda utilizando<br> los campos anteriores</p>

        </div>
      </template>

         <template v-slot:[`item.CardName`]="{ item }">
           {{ formatValue(item.CardName) }}
         </template>
         <template v-slot:[`item.FederalTaxID`]="{ item }">
           {{ formatValue(item.FederalTaxID) }}
         </template>
         <template v-slot:[`item.City`]="{ item }">
           {{ formatValue(item.City) }}
         </template>
         <template v-slot:[`item.Cellular`]="{ item }">
           {{ formatValue(item.Cellular) }}
         </template>
         <template v-slot:[`item.EmailAddress`]="{ item }">
           {{ formatValue(item.EmailAddress) }}
         </template>

      <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="black"
            title="Ver "
            @click="verCliente(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            v-if="item.active"
            color="black"
            title="Editar"
             @click="editarCliente(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
          v-if="item.active"
            color="black"
            title="Ir"
            @click="selectCliente(item)"
          >
            mdi-launch
          </v-icon>
        </template>
      </v-data-table>


      </v-col>
    </v-row>
    <v-row v-else>
       <v-col cols="auto" md="12" class="">
                          <v-data-table
                            :headers="headers"
                            :items="desserts"
                            :search="search"
                            sort-by="secret"
                            class="elevation-0 data__table"
                            :footer-props="{
                              'items-per-page-options': [10, 20],
                            }"
                          >
                            <template v-slot:top>
                              <v-toolbar flat color="white" class="pa-0 pr-0">
                                <v-row class="pa-0 pt-4 ml-0">
                                  <v-col sm="4" class="px-0 ml-0">
                                    <v-text-field
                                      v-model="search"
                                      append-icon="mdi-magnify"
                                      label="Buscador"
                                      dense
                                      filled
                                      rounded
                                      solo
                                    ></v-text-field>
                                  </v-col>

                                  <v-col sm="8" class="pa-0 pt-2 ml-0 text-end">
                                    <span class="text--primary-cart"
                                      >${{ $formatMoney($store.state.carrito.total) }}</span
                                    >
                                    <v-menu
                                      bottom
                                      right
                                      offset-y
                                      origin="top right"
                                      transition="scale-transition"
                                      :close-on-content-click="false"
                                    >
                                      <template v-slot:activator="{ attrs, on }">
                                        <v-btn
                                          class="ml-2"
                                          min-width="0"
                                          text
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="force()"
                                        >
                                          <v-badge
                                            v-if="$store.state.carrito.cantItem > 0"
                                            color="primary"
                                            overlap
                                            bordered
                                          >
                                            <template v-slot:badge>
                                              <span>{{ $store.state.carrito.cantItem }}</span>
                                            </template>

                                            <v-icon class="icon-25">mdi-cart</v-icon>
                                          </v-badge>
                                          <v-badge v-else overlap bordered>
                                            <template v-slot:badge>
                                              <span>0</span>
                                            </template>

                                            <v-icon class="icon-25">mdi-cart</v-icon>
                                          </v-badge>
                                        </v-btn>
                                      </template>
                                      <v-list
                                        :tile="false"
                                        class="pa-0 ma-0"
                                        nav
                                        dense
                                        style="width: 400px"
                                      >
                                        <Carrito
                                          :key="keyCarrito"
                                          :merge-elementos="mergeElementoConCarrito"
                                        />
                                      </v-list>
                                    </v-menu>
                                  </v-col>
                                </v-row>
                              </v-toolbar>
                              <v-row class="mr-2 mt-4 ">
                                  <v-col class="col-md-4 "  >
                                    <p class="ml-2 mt-2 " style="font-size: 20px;font-weight: 600;" >Articulos</p>
                                  </v-col>
                              </v-row>

                                    <v-row class="elevation-3 mx-2 pt-1 pb-0 mt-0 mb-0 rounded-lg">
                                        <v-col  class="col-md-9">
                                          <v-row>
                                          <v-col class="col-md-12 py-0 mt-3   text-left">
                                           <span style="font-weight: 600;">Documento: </span>
                                           <span>{{  clienteSelected.FederalTaxID}}</span>
                                          </v-col>


                                          <v-col class="col-md-12 py-0 mt-0 mb-2   text-left"  >
                                            <span style="font-weight: 600;">Nombre: </span>
                                            <span>{{  clienteSelected.CardName}}</span>
                                          </v-col>
                                          </v-row>

                                        </v-col>
                                        <v-col  class="col-md-3">
                                          <v-row>
                                              <v-col class="col-md-3 py-0 pt-3 mb-0 pr-0 "  style="text-align: end;">


                                              <v-btn
                                                @click="dialogCambiarCliente"
                                                color="success"
                                                class="capitalize-first custom-btn"
                                              >
                                                Cambiar Cliente
                                                <div class="icon-circle ml-2">
                                                  <v-icon style="font-size: 20px">mdi-sync</v-icon>
                                                </div>
                                                <dialog-confirm-three-buttons
                                                  v-model="dialogs['cambiarCliente']"
                                                  @input="dialog = $event"
                                                  title="Cambiar Cliente"
                                                  info="¿Deseas mantener los productos actuales en el carrito para el nuevo cliente o eliminarlos?"
                                                  icon="mdi-autorenew"
                                                  color="#6AA84F"
                                                  buttonConfirmName="Mantener"
                                                  buttonCancelName="Eliminar"
                                                  @confirm="cambiarCliente()"
                                                  @cancel="cambiarCliente(true)"
                                                />
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>



                                    </v-row>


                            </template>

                            <template v-slot:header.unidades="{ header }">
                              <div class="d-flex align-center">
                                <span>{{ header.text }}</span>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                      class="ml-2"
                                      color="default"
                                      style="
                                        height: 18px;
                                        width: 18px;
                                        font-size: 10px;
                                        background-color: black;
                                      "
                                    >
                                      <i class="fa fa-info" aria-hidden="true"></i>
                                    </v-btn>
                                  </template>
                                  <span>La cantidad debe ser multiplo de la cantidad mínima</span>
                                </v-tooltip>
                              </div>
                            </template>

                            <template v-slot:[`item.ForeignName`]="{ item }">
                              <v-row>
                                <v-col class="col-md-12 pt-4 pb-0 row-producto-item-name">
                                  {{ item.ForeignName | capitalizeFirst }}</v-col
                                >
                              </v-row>
                              <v-row class="pt-2 mb-1">
                                <v-col class="col-md-6 pa-0 px-3" style="margin-top: -10px">
                                  <v-row class="pt-2 mb-1">
                                    <v-col class="col-md-2 pa-0 px-3" style="">
                                      <span class="row-producto-item-code">{{
                                        item.ItemCode
                                      }}</span>
                                    </v-col>

                                    <v-col class="col-md-6 pa-0 px-3" style="">
                                      <span
                                        class="custom-badge"
                                        :class="{
                                          'badge-blue': item.db.substring(4, 6) === 'AC',
                                          'badge-green': item.db.substring(4, 6) === 'MJ',
                                        }"
                                      >
                                        {{ item.db.substring(4, 6) }}</span
                                      >
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </template>

                            <template v-slot:[`item.priceList`]="{ item }">
                              <v-row class="text-start">
                                <v-col class="col-md-12 pt-2 pb-0"
                                  ><b>{{
                                    item.priceList.Price != ""
                                      ? "$ " + $formatMoney(item.priceList.Price)
                                      : "&nbsp;"
                                  }}</b></v-col
                                >
                                <v-col class="col-md-12 pa-0 px-2" style="margin-top: -10px">
                                  <span class="row-unidades-compra-min"
                                    >Compra min.
                                    {{ Number(item.SalesQtyPerPackUnit) }} Unid.</span
                                  ></v-col
                                >
                              </v-row>
                            </template>

                            <template v-slot:[`item.unidades`]="{ item }">
                              <div class="d-flex align-center">
                                <v-btn
                                  x-small
                                  class="btn-decrement btn-mas-menos"
                                  :class="item.error != '' && item.error ? 'bg-error' : ''"
                                  @click="decrement(item)"
                                >
                                  <v-icon style="font-size: 16px">mdi-minus</v-icon>
                                </v-btn>


                                <v-text-field
                                  v-model.number="item.unidades"
                                  type="number"
                                  @keydown="$validarSoloNumeros"
                                  class="row-unidades center-number-input no-border"
                                  hide-details
                                  height="25"
                                  :error-messages="item.error"
                                  @input="$validarDivisor(item)"
                                  :class="item.error != '' && item.error ? 'border-error' : ''"
                                ></v-text-field>

                                <v-btn
                                  x-small
                                  class="btn-increment btn-mas-menos"
                                  @click="increment(item)"
                                  :class="item.error != '' && item.error ? 'bg-error' : ''"
                                >
                                  <v-icon style="font-size: 16px">mdi-plus</v-icon>
                                </v-btn>
                                <v-tooltip
                                  bottom
                                  v-if="item.error != '' && item.error"
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                      class="ml-2 error"
                                      style="height: 18px; width: 18px; font-size: 10px"
                                    >
                                      <v-icon style="font-size: 13px">mdi-close</v-icon>
                                    </v-btn>
                                  </template>

                                  <span class="white--text text--small">{{ item.error }}</span>
                                </v-tooltip>
                              </div>

                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                              <v-btn
                                small
                                rounded

                                :class="
                                  item.disabled
                                    ? ''
                                    : item.buttonClass != ''
                                    ? item.buttonClass
                                    : 'btn-primary'
                                "
                                class="px-3 capitalize-first custom-btn"
                                :disabled="item.disabled || item.disabled == null"
                                @click="AgregarProductos(item)"
                              >
                                <small
                                  :class="
                                    item.buttonClass != '' || !item.disabled
                                      ? 'capitalize-first text-button-carrito'
                                      : 'capitalize-first text-button-carrito-vacio'
                                  "
                                >
                                  {{ item.buttonTxt }}
                                </small>
                              </v-btn>
                            </template>
                          </v-data-table>

                      <v-snackbar
                        v-model="snackbar"
                        :bottom="true"
                        :color="color"
                        :timeout="timeout"
                      >
                        <div v-html="text"></div>

                        <template v-slot:action="{ attrs }">
                          <v-btn dark text v-bind="attrs" @click="snackbar = false">
                            Cerrar
                          </v-btn>
                        </template>
                      </v-snackbar>
                      <vue-confirm-dialog></vue-confirm-dialog>


      </v-col>

    </v-row>

     <dialog-form-client
              v-model="dialogFormCliente"
              @input="dialogFormCliente = $event"
              :form="form"
              :errors="errors"
              :disabled="disabled"
              @close="closeDialog"
              :mode="mode"
              :db="$db('ANMAC')"
            />


  </v-container>
</template>



<style scoped>
.capitalize-first {
  text-transform: lowercase; /* Convierte todo el texto a minúsculas */
}

.capitalize-first::first-letter {
  text-transform: uppercase; /* Capitaliza solo la primera letra */
}
</style>

<script>

import Swal from "sweetalert2";
import Carrito from "@/views/carrito/Carrito.vue";

import DialogFormClient from "@/views/dialogs/FormClient.vue";
//import DialogConfirmar from "@/views/dialogs/Confirmar.vue";
import DialogConfirmThreeButtons from "@/views/dialogs/ConfirmThreeButtons.vue";

export default {
  components: {
    Carrito,
    DialogFormClient,
    DialogConfirmThreeButtons
  },
  name: "ElementosAnmacVenta",
  data() {
    return {
      dialogs: {
        cambiarCliente: false,
      },
      mode: 'create',
      snackbar: false,
      text: "Registro Insertado",
      color: "success",
      timeout: 4000,
      searchCuit: "",
      searchName: "",
      headersClientes: [

        { text: "Nombre", value: "CardName" },
        { text: "Documento", value: "FederalTaxID" },
        { text: "Ciudad", value: "City" },
        { text: "Celular", value: "Cellular" },
        { text: "Mail", value: "EmailAddress" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      clientes: [],
      disabled: true,

      headers:  [
          {
            text: "Codigo",
            filterable: true,
            value: "ItemCode",
            align: " d-none",
          },
          {
            text: "Producto",
            filterable: true,
            value: "ForeignName",
            width: "45%",
          },
          { text: "P. Unitario", filterable: true, value: "priceList" },
          {
            text: "Unidades",
            filterable: true,
            value: "unidades",
            sortable: false,
            width: "25%",
          },
          { text: "", value: "actions", sortable: false },
        ],
      desserts: [],
      busquedaCliente :true,
      keyCarrito: 0,
      search: "",
      route: "elementosAnmac",
      apiRoute: "/api/servicelayer/clientes/search",
      clienteSelected:[],
      dialogFormCliente:false,
      form: {
        tipoDocumento:  null,
        numeroDocumento:  null,
        nombreApellido:  null,
        calle:  null,
        numero:  null,
        piso:  null,
        depto:  null,
        pais:  null,
        provincia:  null,
        localidad: null,
        cp:  null,
        telefono: null,
        celular:null,
        mail: null
      },
      errors:{},
      prueba:0

    };
  },

  mounted() {

    let CardCode = this.$route.params.CardCode;

    if (CardCode) {

      this.busquedaCliente = false;

      this.clienteSelected = this.$store.state.carrito.cliente;

      this.getElementos();
    }
    else
    {
      this.clienteSelected=[];
      this.$store.state.carrito.cliente = [];

      this.fetchClientesAnmac();
    }
  },

  methods: {

    async increment(item) {

      let divisor = Number(item.unidades) / Number(item.SalesQtyPerPackUnit);
      //let res = parseInt(Number(divisor));
      let index = this.desserts.findIndex((p) => p.ItemCode === item.ItemCode);

      if (index !== -1) {
        // Actualizar las unidades del producto
        //this.desserts[index].unidades =  (res+1) * parseInt(Number(item.SalesQtyPerPackUnit));
        let NuevasUnidades =
          Math.ceil(
            item.unidades / Number(this.desserts[index].SalesQtyPerPackUnit)
          ) * Number(this.desserts[index].SalesQtyPerPackUnit);
        if (NuevasUnidades == item.unidades) {
          NuevasUnidades =
            Number(item.unidades) +
            Number(this.desserts[index].SalesQtyPerPackUnit);
        }

        this.desserts[index].unidades = NuevasUnidades;

        this.desserts[index].disabled = false;
        this.desserts[index].buttonTxt = "Agregar al carrito";
        this.desserts[index].buttonClass = "";
        this.desserts[index].error = "";
        // Forzar la reactividad reemplazando el objeto
        this.$set(this.desserts, index, { ...this.desserts[index] });
      }
  },
    async decrement(item) {
      /*let resp = await this.$validarDivisor(item);

      if (resp == false) {
        return;
      }*/

      // Buscar el índice del producto en el array 'desserts' por 'ItemCode'
      let index = this.desserts.findIndex((p) => p.ItemCode === item.ItemCode);

      if (index !== -1) {
        // Actualizar las unidades del producto
        if (
          this.desserts[index].unidades -
            Number(this.desserts[index].SalesQtyPerPackUnit) >
          0
        ) {


          let NuevasUnidades =
            Math.floor(
              item.unidades / Number(this.desserts[index].SalesQtyPerPackUnit)
            ) * Number(this.desserts[index].SalesQtyPerPackUnit);

          if (NuevasUnidades == item.unidades) {
            NuevasUnidades =
              Number(item.unidades) -
              Number(this.desserts[index].SalesQtyPerPackUnit);
          }

          this.desserts[index].unidades = NuevasUnidades;
          this.desserts[index].disabled = false;
          this.desserts[index].buttonTxt = "Agregar al carrito";
          this.desserts[index].buttonClass = "";
          this.desserts[index].error = "";
        } else {
          this.desserts[index].unidades = 0;

          this.desserts[index].disabled = true;
          this.desserts[index].buttonTxt = "Agregar al carrito";
          //this.desserts[index].buttonClass = '';

          this.desserts[index].error =
            "El valor ingresado debe ser mayor que 0";
        }

        // Forzar la reactividad reemplazando el objeto
        this.$set(this.desserts, index, { ...this.desserts[index] });
      }
    },

    AgregarProductos(item) {
      // Usa una mutation para agregar o actualizar el item en el carrito
      if (item.unidades > 0) {
        this.keyCarrito++;
        // Cambiar color y etiqueta del botón
        item.buttonClass = "btn-success";
        item.buttonTxt = "Agregado al carrito";
        item.error = "";
        this.$store.commit("carrito/addOrUpdateItem", item);

        this.$forceUpdate();
      } else {
        Swal.alert(
          "No es posible agregar el producto",
          "Para agregar el producto tenes que ingresar la cantidad de unidades"
        );
      }
    },
    dialogCambiarCliente() {
      if(this.$store.state.carrito){
        if(this.$store.state.carrito.data.length>0){
        this.dialogs.cambiarCliente = true;
        }
        else{
          /*this.busquedaCliente = true;
          this.clienteSelected = [];*/
          this.$router.push({
            name: "elementos_anmac_venta"});

        }
      }
    },
    formatValue(value) {
      return value !== null && value !== undefined && value !== "" ? value : "null";
    },
    closeDialog(data) {
      if (data) {
        this.searchCuit = data;
        this.searchName = "";
        this.buscar();
      }
    },

    fetchClientesAnmac(){

      Swal.alertGetInfo("Buscando información");

       setTimeout(() => {
          this.clientes =   [ ] ;

          Swal.close();
        },500);
    },


    buscar() {
      Swal.alertGetInfo("Buscando información");

      let url = `${this.apiRoute}/`+process.env.VUE_APP_DB_ANMAC;

      const params = [];
      if (this.searchCuit) {
        params.push(`documento=${encodeURIComponent(this.searchCuit)}`);
      }
      if (this.searchName) {
        params.push(`nombre=${encodeURIComponent(this.searchName.toUpperCase())}`);
      }

      // Agregar los parámetros a la URL
      if (params.length) {
        url += `?${params.join("&")}`;
      }

      this.$axiosApi
        .sendRequest(url, "get")
        .then((response) => {
          if (response.data && response.data.data) {
            this.clientes = response.data.data.map((cliente) => {
              const firstAddress = cliente.BPAddresses?.[0] || {};
              return {
                ...cliente,
                City: firstAddress.City || "null"
              };
            });
          } else {
            this.clientes = [];
          }
          Swal.close();
        })
        .catch((error) => {
          console.error("Error al buscar clientes:", error);
          Swal.close();
          Swal.fire("Error", "No se pudieron obtener los datos. Intenta nuevamente.", "error");
        });
    },


    nuevoCliente(){

      this.form= {
        tipoDocumento:  null,
        numeroDocumento:  null,
        nombreApellido:  null,
        calle:  null,
        numero:  null,
        piso:  null,
        depto:  null,
        pais:  null,
        provincia:  null,
        localidad: null,
        cp:  null,
        telefono: null,
        celular:null,
        mail: null
      };
      this.mode = 'create';
      this.errors={};
      this.customErrorMessage = "";
      this.disabled=false;
      this.dialogFormCliente=true;
    },

    async editarCliente(item) {
      this.errors = {}
      let locationInternalCode = await this.$getLocationInternalCode(item.BPAddresses[0]?.State);
      this.form = {
        tipoDocumento: "Cuit", // Establecemos "Cuit" como tipo de documento
        numeroDocumento: item.FederalTaxID || "", // Número de documento
        nombreApellido: item.CardName || "", // Nombre y Apellido
        cardCode: item.CardCode || "", // Cardcode
        calle: item.BPAddresses[0]?.Street || "", // Calle
        numero: item.BPAddresses[0]?.StreetNo || "", // Número
        piso: item.BPAddresses[0]?.BuildingFloorRoom || "", // Piso
        depto: item.BPAddresses[0]?.Block || "", // Depto
        pais: item.BPAddresses[0]?.Country || "", // País
        provincia: locationInternalCode || "", // Provincia
        localidad: item.BPAddresses[0]?.City || "", // Localidad
        rowNum: item.BPAddresses[0]?.RowNum ?? null, // Número de row a actualizar
        addressName: item.BPAddresses[0]?.AddressName ?? null, // Nombre de address
        cp: item.BPAddresses[0]?.ZipCode || "", // Código postal
        telefono: item.Phone1 || "", // Teléfono
        celular: item.Cellular || "", // Celular
        mail: item.EmailAddress || "" // Correo electrónico
      };
      this.mode = 'edit';
      this.disabled = false;
      this.dialogFormCliente = true;
    },
    async verCliente(item) {
      let locationInternalCode = await this.$getLocationInternalCode(item.BPAddresses[0]?.State);
      this.form = {
        tipoDocumento: "Cuit", // Establecemos "Cuit" como tipo de documento
        numeroDocumento: item.FederalTaxID || "", // Número de documento
        nombreApellido: item.CardName || "", // Nombre y Apellido
        calle: item.BPAddresses[0]?.Street || "", // Calle
        numero: item.BPAddresses[0]?.StreetNo || "", // Número
        piso: item.BPAddresses[0]?.BuildingFloorRoom || "", // Piso
        depto: item.BPAddresses[0]?.Block || "", // Depto
        pais: item.BPAddresses[0]?.Country || "", // País
        provincia: locationInternalCode || "", // Provincia
        localidad: item.BPAddresses[0]?.City || "", // Localidad
        cp: item.BPAddresses[0]?.ZipCode || "", // Código postal
        telefono: item.Phone1 || "", // Teléfono
        celular: item.Cellular || "", // Celular
        mail: item.EmailAddress || "" // Correo electrónico
      };
      this.mode = 'view';
      this.disabled = true;
      this.dialogFormCliente = true;
      this.errors = {}
    },

    getItemClass: function (item) {
       return item.active  === false ?  "inactive-item" :'';
    },

    selectCliente(selectedCliente) {
      this.busquedaCliente = false;

      this.$store.state.carrito.cliente = selectedCliente;

      this.$router.push({
        name: "elementos_anmac_venta",
        params: { CardCode: selectedCliente.CardCode } // Pasa el parámetro "id" a la ruta
      });





    },


    cambiarCliente(remove=false){
      if(remove){
        this.$store.commit("carrito/remove");
      }
      this.dialogs.cambiarCliente = false;
      this.busquedaCliente = true;
      this.clienteSelected = [];
      this.$router.push({
        name: "elementos_anmac_venta"
      });

    },
    getElementos() {
      if(!this.$store.state.carrito.cliente){
          Swal.$alertError('Atención', 'Debe seleccionar un cliente para continuar con la venta de elementos ANMaC');
          return;
      }

      let userProps = this.$store.state.carrito.cliente;

      let formSap = {};
      const trueProps = Object.entries(userProps)
        .filter(([key, value]) => (value === true || value === 'tYES'))
        .map(([key]) => key);

      trueProps.push(this.$store.state.carrito.cliente.PriceListNum);

      formSap.userProps = trueProps;
      Swal.alertGetInfo("Buscando información");

      this.$axiosApi
        .post(this.route, formSap)
        .then((r) => {
          if (r.data.data) {
            //console.log(r.data.data);
            this.desserts = r.data.data;
            Swal.close();
            this.mergeElementoConCarrito();

          }


        })
        .catch(function (error) {
          console.log(error);
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";

          //Swal.close();
        });
    },

    mergeElementoConCarrito() {
      let carritoData = this.$store.state.carrito.data; // El array del carrito

      this.desserts.forEach((dessert) => {
        // Encontrar si el ItemCode del dessert está en el carrito
        let carritoItem = carritoData.find(
          (item) => item.ItemCode === dessert.ItemCode
        );

        if (carritoItem) {
          dessert.unidades = carritoItem.unidades;
          dessert.error = carritoItem.error;
          dessert.disabled = false;
          dessert.buttonTxt = "Agregado al carrito";
          dessert.buttonClass = "btn-success";
        } else {
          dessert.unidades = "";
          dessert.error = "";
          dessert.disabled = true;
          dessert.buttonTxt = "Agregar al carrito";
          dessert.buttonClass = "";
        }

        dessert.TipoElementosId = 5;
        dessert.TipoElementosDesc = "Elementos ANMaC";
      });
    },

     force() {
      this.keyCarrito++;
      this.$forceUpdate();
    },



  },


};
</script>
